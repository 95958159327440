










import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class ActionButton extends Vue {
  @Prop() private action: any;
  @Prop() private text!: string;
  @Prop() private css!: string;
  @Prop() private disabled!: boolean;
  @Prop() private block!: boolean;
  @Prop() private small!: boolean;
  @Prop({default: undefined}) private width!: number | string;
  @Prop({default: undefined}) private height!: number | string;
  @Prop({default: false}) private fixed!: boolean;
  @Prop({default: false}) private loading!: boolean;
  @Prop({default: false}) private bottom!: boolean;
  @Prop({ default: '' }) private icon!: string;
  @Prop({ default: '' }) private iconRight!: string;
  @Prop({ default: 'primary' }) private color!: string;
}
