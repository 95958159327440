var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'full-height': _vm.$vuetify.breakpoint.mdAndUp, 'without-app-bar': _vm.$vuetify.breakpoint.smAndDown}},[_c('Loader'),(!_vm.appLoading)?_c('div',{staticClass:"px-6 content-container"},[_c('v-row',{staticClass:"content-container",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',[_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{staticClass:"mt-14",attrs:{"cols":"12","lg":"4","md":"5"}},[(!_vm.showUserDetails)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"primary"}},[_c('ActionButton',{staticClass:"white--text",attrs:{"height":"40","block":"","text":"Add a new super user"},on:{"click":function($event){return _vm.$router.push({name: 'create-super-user'})}}})],1)],1)],1):_vm._e(),(!_vm.showUserDetails)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"custom-field",attrs:{"dense":"","height":"44","outlined":"","placeholder":"Search super users","persistent-placeholder":"","prepend-inner-icon":"mdi-magnify","item-value":"id","filter":_vm.customSearch,"items":_vm.superUsers,"return-object":""},on:{"input":function($event){_vm.userDetails = $event; _vm.updateDetails++}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" "),_c('v-spacer'),_vm._v(" ("+_vm._s(item.email)+") ")]}}],null,false,2959783071),model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}})],1)],1):_vm._e(),(_vm.superUsers.length)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.superUsers),function(user){return _c('div',{key:user.id},[_c('UserCard',{attrs:{"user":user,"color":user.email === _vm.userDetails.email ? 'rgba(50,55,75,0.12)' : 'tertiary'},on:{"clicked:user":function($event){_vm.userDetails = $event; _vm.updateDetails++}}})],1)}),0)],1):_vm._e()],1)],1),(_vm.superUsers.length)?_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"7"}},[_c('SuperUserDetails',{key:_vm.updateDetails,attrs:{"selected-user":_vm.userDetails},on:{"refresh:user":_vm.getSuperUsers,"remove:user":_vm.getSuperUsers}})],1)],1):_vm._e()],1):(_vm.superUsers.length && _vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"mt-4"},[(!_vm.showUserDetails)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"primary"}},[_c('ActionButton',{staticClass:"white--text",attrs:{"height":"40","block":"","text":"Add a new super user"},on:{"click":function($event){return _vm.$router.push({name: 'create-super-user'})}}})],1)],1):_vm._e(),(!_vm.showUserDetails)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"custom-field",attrs:{"hide-details":"","dense":"","height":"44","outlined":"","placeholder":"Search super users","persistent-placeholder":"","prepend-inner-icon":"mdi-magnify","item-value":"id","filter":_vm.customSearch,"items":_vm.superUsers,"return-object":""},on:{"change":function($event){_vm.userDetails = $event; _vm.showUserDetails = true;},"input":function($event){_vm.userDetails = $event; _vm.updateDetails++}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" "),_c('v-spacer'),_vm._v(" ("+_vm._s(item.email)+") ")]}}],null,false,2959783071),model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}})],1):_vm._e(),(!_vm.showUserDetails)?_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"5"}},_vm._l((_vm.superUsers),function(user){return _c('div',{key:user.id},[_c('UserCard',{attrs:{"user":user},on:{"clicked:user":function($event){_vm.userDetails = $event; _vm.showUserDetails = true;}}})],1)}),0)],1):_vm._e(),_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"12"}},[(_vm.showUserDetails)?_c('SuperUserDetails',{attrs:{"selected-user":_vm.userDetails},on:{"refresh:user":_vm.getSuperUsers,"click:back":function($event){_vm.showUserDetails = false; _vm.userDetails = null; _vm.userSearch = '';},"remove:user":function($event){_vm.showUserDetails = false; _vm.getSuperUsers()}}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }