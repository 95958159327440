var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'full-height': _vm.$vuetify.breakpoint.mdAndUp, 'without-app-bar': _vm.$vuetify.breakpoint.smAndDown}},[_c('Loader'),(!_vm.appLoading)?_c('div',{staticClass:"px-6 content-container"},[_c('v-row',{staticClass:"content-container",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',[_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{staticClass:"mt-14",attrs:{"cols":"12","lg":"4","md":"5"}},[(!_vm.showAccountDetails)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"custom-field",attrs:{"dense":"","height":"44","outlined":"","placeholder":"Search customer accounts","persistent-placeholder":"","item-value":"name","item-text":"name","filter":_vm.customSearch,"search-input":_vm.accountSearch,"prepend-inner-icon":"mdi-magnify","items":_vm.customerAccounts,"return-object":""},on:{"input":function($event){_vm.selectedAccount = $event; _vm.updateSelected++}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer'),_vm._v(" ("+_vm._s(item.account_code)+") ")]}}],null,false,2531046240),model:{value:(_vm.searchedAccount),callback:function ($$v) {_vm.searchedAccount=$$v},expression:"searchedAccount"}})],1)],1):_vm._e(),(_vm.customerAccounts.length)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.customerAccounts),function(account){return _c('div',{key:account.id},[_c('AccountCard',{attrs:{"account":account,"color":_vm.selectedAccount.daizy_project_id === account.daizy_project_id ? 'rgba(50,55,75,0.12)' : 'tertiary'},on:{"clicked:account":function($event){_vm.selectedAccount = $event}}})],1)}),0)],1):_vm._e()],1)],1),(_vm.customerAccounts.length)?_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"7"}},[_c('CustomerAccountDetails',{attrs:{"selected-account":_vm.selectedAccount},on:{"remove:account":_vm.getCustomerAccounts,"refresh:accounts":_vm.getCustomerAccounts}})],1)],1):_vm._e()],1):(_vm.customerAccounts.length && _vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"mt-4"},[(!_vm.showAccountDetails)?_c('v-col',{staticClass:"px-4",attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"custom-field mb-4",attrs:{"hide-details":"","dense":"","height":"44","outlined":"","placeholder":"Search customer accounts","persistent-placeholder":"","item-value":"name","item-text":"name","filter":_vm.customSearch,"search-input":_vm.accountSearch,"prepend-inner-icon":"mdi-magnify","items":_vm.customerAccounts,"return-object":""},on:{"change":function($event){_vm.selectedAccount = $event; _vm.showAccountDetails = true;},"input":function($event){_vm.selectedAccount = $event; _vm.updateSelected++}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer'),_vm._v(" ("+_vm._s(item.account_code)+") ")]}}],null,false,2531046240),model:{value:(_vm.searchedAccount),callback:function ($$v) {_vm.searchedAccount=$$v},expression:"searchedAccount"}})],1):_vm._e(),(!_vm.showAccountDetails)?_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"5"}},_vm._l((_vm.customerAccounts),function(account){return _c('div',{key:account.id},[_c('AccountCard',{attrs:{"account":account},on:{"clicked:account":function($event){_vm.selectedAccount = $event; _vm.showAccountDetails = true;}}})],1)}),0)],1):_vm._e(),_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"12"}},[(_vm.showAccountDetails)?_c('CustomerAccountDetails',{attrs:{"selected-account":_vm.selectedAccount},on:{"click:back":function($event){_vm.showAccountDetails = false; _vm.accountSearch = ''; _vm.searchedAccount = null},"remove:account":function($event){_vm.showAccountDetails = false; _vm.getCustomerAccounts()},"refresh:accounts":function($event){return _vm.getCustomerAccounts($event);}}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }