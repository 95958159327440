




















































import {Vue, Component, Prop} from "vue-property-decorator";
import SiteWizard from "@/views/wizards/SiteWizard.vue";
import DeviceWizard from "@/views/wizards/DeviceWizard.vue";
import GatewayWizard from "@/views/wizards/GatewayWizard.vue";
import {Device, Site} from "../types";
import SiteService from "@/services/site.service";
import Select from "@/components/Select.vue";
import {Route} from "vue-router";
import {Getter, Mutation} from "vuex-class";
import Loader from "@/components/Loader.vue";


enum SetupSteps {
  SiteWizard,
  GatewayWizard,
  DeviceWizard,
}

@Component({
  components: {DeviceWizard, GatewayWizard, SiteWizard, Loader, Select}
})
export default class Setup extends Vue {
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Getter private hasPermission!: any;
  @Mutation private toggleLoading!: any;
  private SetupSteps = SetupSteps;
  private activeStep: SetupSteps = SetupSteps.SiteWizard;
  private sites: Site[] = [];
  private devices: Device[] = [];
  private site: Partial<Site> = {};
  private gatewayStep = 0;
  private siteStep = 0;
  private siteOnly = false;
  private reset = 0;

  private async mounted() {
    await this.loadSites();
  }

  private async loadSites() {
    this.toggleLoading(true);
    try {
      if (this.siteOnly) {
        const site = (await SiteService.getSites(this.accountId, undefined, this.site.id)).find((s) => s.id === this.site.id);
        Vue.set(this, 'site', site)
      } else {
        this.sites = await SiteService.getSites(this.accountId)

        if ('siteId' in this.$route.params) {
          const site = this.sites.find((s) => s.id === Number(this.$route.params.siteId))
          this.site = site || {};
          this.siteHasGateway ? this.activeStep = SetupSteps.DeviceWizard : this.activeStep = SetupSteps.GatewayWizard;
        }
      }
    } finally {
      this.toggleLoading(false);
    }
  }


  private get siteHasGateway() {
    return this.site && this.site.gateway && this.site.gateway.id;
  }
}
