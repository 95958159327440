



















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Asset} from "@/types";

@Component
export default class Select extends Vue {
  @Prop({default: ''}) private hint!: string;
  @Prop() private label!: string;
  @Prop() private customClass!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private editable!: boolean;
  @Prop() private clearable!: boolean;
  @Prop() private returnObject!: boolean;
  @Prop() private height!: string;
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: false}) private hideDetails!: boolean;
  @Prop() private items!: any[];
  private input = '';
  private isDisabled = false;

  private mounted() {
    this.isDisabled = this.disabled;
    this.input = this.value;
  }


  private set selectedValue(val: string) {
    Vue.set(this, 'input', val)
    this.$emit('update:value', val)
  }

  private get selectedValue() {
    return this.input;
  }
}
