

































































import {Component, Vue, Watch} from "vue-property-decorator";
import UserDetails from "@/views/UserDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
import UserService from "@/services/user.service";
import {Action, Getter} from "vuex-class";
import {User} from "@/types";
import Loader from "@/components/Loader.vue";
@Component({
  components: {Loader, ActionButton, UserDetails}
})
export default class UserProfile extends Vue {
  @Getter private user!: any;
  @Getter private accountId!: any;
  @Getter private appLoading!: boolean;
  @Action private refreshUser!: () => Promise<void>;
  private loading = false;
  private validName = true;
  private saving = false;
  private showEditName = false;
  private userDetails: { firstName: string; lastName: string; alertsEnabled: boolean } = {
    firstName: '',
    lastName: '',
    alertsEnabled: true
  };

  @Watch('user', {immediate: true})
  private onUserUpdate() {
    if (this.user) {
      this.userDetails = {
        firstName: this.user.first_name,
        lastName: this.user.last_name,
        alertsEnabled: this.user.alerts_enabled
      };
    }
  }

  private resetName() {
    this.userDetails = {
      firstName: this.user.first_name,
      lastName: this.user.last_name,
      alertsEnabled: this.userDetails.alertsEnabled
    };
  }
  private async saveEditUser() {
    this.saving = true;

    try {
      const payload = {
        user: {
          first_name: this.userDetails.firstName,
          last_name: this.userDetails.lastName,
          alerts_enabled: this.userDetails.alertsEnabled
        }
      };
      await UserService.updateUser(this.accountId, this.user.id, payload);
    }
    finally {
      this.showEditName = false;
      this.saving = false;
    }
    await this.refreshUser();
  }
}
