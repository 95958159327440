


































































import {Component, Vue, Watch} from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import {Action, Getter, Mutation} from "vuex-class";
import AccountCard from "@/components/AccountCard.vue";
import CustomerAccountDetails from "@/views/admin/CustomerAccountDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
import UserService from "@/services/user.service";
import AdminService from "@/services/admin.service";
import {Account, User} from "@/types";
import store from "@/store";
import {RouteScope} from "@/router";
import {NavigationGuardNext, Route} from "vue-router";
@Component({
  components: {ActionButton, CustomerAccountDetails, AccountCard, Loader}
})
export default class CustomerAccounts extends Vue {
  @Getter private accountId!: number;
  @Getter private user!: User;
  @Getter private appLoading!: boolean;
  @Action private showToastError!: any;
  @Mutation private toggleLoading!: any;
  private selectedAccount: Account = {} as Account;
  private customerAccounts: Account[] = [];
  private showAccountDetails = false;
  private updateSelected = 0;
  private searchedAccount: Account | null = null;
  private accountSearch = '';

  @Watch('searchedAccount')
  private onSearch() {
    if (!this.searchedAccount) {
      this.selectedAccount = this.customerAccounts[0];
    }
  }

  private async mounted() {
    await this.getCustomerAccounts();
  }

  private async getCustomerAccounts(daizyProjectId?: number) {
    this.toggleLoading(true);
    try {
      this.customerAccounts = await AdminService.getAccounts();
      if (this.accountId) {
        const account = this.customerAccounts.find((u) => u.daizy_project_id === this.accountId);
        if (account) {
          this.selectedAccount = account;
        }
      } else {
        let accountIndex = 0;
        if (daizyProjectId) {
          accountIndex = this.customerAccounts.findIndex((acc) => acc.daizy_project_id === daizyProjectId);
          if (accountIndex < 0) {
            this.showAccountDetails = false;
            accountIndex = 0;
          }
        }
        this.selectedAccount = this.customerAccounts[accountIndex];
      }
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.toggleLoading(false);
    }
  }

  private get customSearch() {
    return (item: any, search: string) => {
      if (item) {
        return (item.name.toLowerCase() && item.name.toLowerCase().indexOf(search) > -1) || (item.account_code.toLowerCase() && item.account_code.toLowerCase().indexOf(search) > -1);
      } else {
        return false;
      }
    };
  }
}
