









































































































import {Component, Prop, Vue} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import {Getter} from "vuex-class";
import {User} from "@/types";
import AdminService from "@/services/admin.service";

@Component({
  components: {ActionButton}
})
export default class SuperUserDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private user!: any;
  @Prop() private selectedUser!: User;
  private showEditName = false;
  private deleteLoading = false;
  private deleteDialog = false;
  private loading = false;
  private saving = false;
  private validName = false;
  private userDetails: { firstName: string; lastName: string; alertsEnabled: boolean } = {
    firstName: '',
    lastName: '',
    alertsEnabled: true
  };

  private mounted() {
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.selectedUser.alerts_enabled
    }
  }

  private async saveEditSuperUser() {
    if (this.selectedUser.id) {
      const payload = {
        first_name: this.userDetails.firstName,
        last_name: this.userDetails.lastName,
      }

      await AdminService.updateSuperUser(this.selectedUser.id, payload);

      this.showEditName = false;
      this.$emit('refresh:user', this.selectedUser.id)
      this.saving = false;
    }
  }

  private async removeSuperUser(userId: number) {
    this.deleteLoading = true
    try {
      await AdminService.removeSuperUser(userId)
      this.deleteDialog = false;
    } finally {
      this.$emit('remove:user')
      this.deleteLoading = false;
    }
  }

  private resetName() {
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.userDetails.alertsEnabled
    }
  }
}
