


















































import {Component, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import {Getter} from "vuex-class";
import AdminService from "@/services/admin.service";

@Component({
  components: {TextField, ActionButton}
})
export default class SuperUserWizard extends Vue {
  @Getter private accountId!: number;
  private userDetails: { firstName: string; lastName: string; email: string; } = {
    firstName: '',
    lastName: '',
    email: '',
  };
  private valid = false;
  private saving = false;
  private error = '';
  private rules = {
    first_name: [
      ((v: string) => !!v || 'First name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    last_name: [
      ((v: string) => !!v || 'Last name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    email: [
      ((v: string) => !!v || 'Email is required'),
      ((v: string) => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'),
    ]
  };

  private async saveSuperUser() {
    this.saving = true;

    const payload = {
      first_name: this.userDetails.firstName,
      last_name: this.userDetails.lastName,
      email: (this.userDetails.email).toLowerCase()
    }
    try {
      await AdminService.addSuperUser(payload);
      await this.$router.replace({ name: 'super-users' });
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.saving = false;
    }
  }

  private setValue(obj: string, value: string | number) {
    Vue.set(this.userDetails, obj, value ? value : null);
  }

}
