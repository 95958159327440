import {Asset, Device, MonitoringPoint, Room} from "../types";
import { appAxios } from '../app.axios';
import store from "@/store";

export class DeviceService {
  public async getDevices(): Promise<Device[]> {
    const url = `/projects/${store.getters.accountId}/devices`;
    return (await appAxios.get(url)).data;
  }

  public async getDevice(id: number): Promise<Device> {
    const url = `/projects/${store.getters.accountId}/devices/${id}`;
    return (await appAxios.get(url)).data;
  }

  public async scanDevice(scanResult: string): Promise<{ network_id: string; device_id?: string }> {
    const url = `/projects/${store.getters.accountId}/devices/scan`;
    return (await appAxios.post(url, { scanResult })).data;
  }

  public async createRoom(data: { name: string | null, siteId: number, lat: number, lng: number }): Promise<Room> {
    const url = `/projects/${store.getters.accountId}/locations/rooms/create`;
    const details = {
      name: data.name,
      parentId: data.siteId,
      lat: data.lat,
      lng: data.lng
    }
    return (await appAxios.post(url, details)).data;
  }

  public async createAssetLocation(data: { name: string, siteId: number, assetType: string, manufacturer: string, lat: number, lng: number, roomId?: number }): Promise<Asset> {
    const url = `/projects/${store.getters.accountId}/locations/assets/create`;
    const details = {
      name: data.name,
      parentId: data.roomId,
      assetType: data.assetType,
      manufacturer: data.manufacturer,
      lat: data.lat,
      lng: data.lng
    }
    return (await appAxios.post(url, details)).data;
  }

  public async createMonitoringPoint(data: { name?: string, assetId?: number, lat: number, lng: number, configuration: { minTemp?: number, maxTemp?: number, alertThreshold?: number } }): Promise<MonitoringPoint> {
    const url = `/projects/${store.getters.accountId}/locations/monitoringPoints/create`;
    const details = {
      name: data.name,
      parentId: data.assetId,
      lat: data.lat,
      lng: data.lng,
      minTemp: data.configuration.minTemp,
      maxTemp: data.configuration.maxTemp,
      alertThreshold: data.configuration.alertThreshold
    }
    return (await appAxios.post(url, details)).data;
  }

  public async mountDevice(monitoringPointId: number, deviceId: number): Promise<Device> {
    const url = `/projects/${store.getters.accountId}/location/${monitoringPointId}/devices/${deviceId}/mount`;
    return (await appAxios.post(url)).data;
  }

  public async getDeviceActivity(deviceTypeSlug: string, deviceIdentifier: string) {
    const url = `/projects/${store.getters.accountId}/devices/${deviceIdentifier}/slug/${deviceTypeSlug}/activity`
    return (await appAxios.get(url)).data;
  }

  public async removeDevice(deviceId: number) {
    const url = `/projects/${store.getters.accountId}/devices/${deviceId}`;
    return (await appAxios.delete(url)).data;
  }

  public async findDispatchedDevice(searchString: string) {
    const url = `/projects/${store.getters.accountId}/devices/searchDispatched`;
    return (await appAxios.post(url, { searchString: searchString })).data;
  }

  public async addDeviceToAccount(devEui: string) {
    const url = `/projects/${store.getters.accountId}/devices/assign`;
    return (await appAxios.post(url, { devEui }))
  }
}

export default new DeviceService();
