


































import {Component, Prop, Vue} from "vue-property-decorator";
import {User} from "@/types";

@Component
export default class UserCard extends Vue {

  @Prop() private user!: User;
  @Prop({default: 'tertiary'}) private color!: string;

  private get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 'max-width: 48vw';
      case 'sm': return 'max-width: 80vw';
      case 'md': return 'max-width: 22vw';
      case 'lg': return 'max-width: 13vw';
      default: return 'max-width: 100vw';
    }
  }
}
