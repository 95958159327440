

































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import {Role, Site} from "@/types";
import UserService from "@/services/user.service";
import SiteService from "@/services/site.service";
import {Getter} from "vuex-class";

@Component({
  components: {TextField, ActionButton}
})
export default class UserWizard extends Vue {
  @Getter private accountId!: number;
  private addingSiteRole = false;
  private isConsumer = false;
  private newSiteRole: {site_id: number; roles: number[]} = { site_id: 0, roles: [] };
  private userDetails: { firstName: string; lastName: string; email: string; } = {
    firstName: '',
    lastName: '',
    email: '',
  };
  private valid = false;
  private saving = false;
  private error = '';
  private selectedRole = 0;
  private sites: Site[] = [];
  private editingSiteRole: {[key: number]: boolean} = {};
  private roles: Role[] = [];
  private siteRoles: Array<{site_id: number; roles: number[]}> = [];
  private rules = {
    first_name: [
      ((v: string) => !!v || 'First name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    last_name: [
      ((v: string) => !!v || 'Last name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    email: [
      ((v: string) => !!v || 'Email is required'),
      ((v: string) => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'),
    ]
  };

  @Watch('selectedRole')
  private onRoleChange(newVal: number) {
    if (newVal === 1) {
      this.isConsumer = false;
      Vue.set(this, 'siteRoles', [])
    } else {
      this.isConsumer = true;
    }
  }

  private async mounted() {
    this.roles = await UserService.getRoles();
    this.sites = await SiteService.getSites(this.accountId, 1);
  }

  private addSiteAccess() {
    if (this.newSiteRole.site_id) {
      Vue.set(this.newSiteRole, 'roles', [2]);

      this.siteRoles.push(this.newSiteRole)
      this.newSiteRole = { site_id: 0, roles: [] };
    }
  }

  private async saveUser() {
    this.saving = true;
    this.addSiteAccess();

    const payload = {
      user: {
        first_name: this.userDetails.firstName,
        last_name: this.userDetails.lastName,
        alerts_enabled: true,
        email: (this.userDetails.email).toLowerCase()
      },
      roles: this.isConsumer ? null : [1],
      sites: this.isConsumer ? this.siteRoles : [],
    }
    try {
      await UserService.addUser(this.accountId, payload);
      await this.$router.replace({ name: 'users' });
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.saving = false;
    }
  }


  private setValue(obj: string, value: string | number) {
    Vue.set(this.userDetails, obj, value ? value : null);
  }

  private async addSiteRole() {
    this.addingSiteRole = true;
  }

  private cancelNewSiteRole() {
    this.addingSiteRole = false;
    this.newSiteRole = { site_id: 0, roles: [] };
  }

  private removeSiteRoles(role: { site_id: number, roles: number[] }) {
    this.siteRoles = this.siteRoles.filter((sr) => sr.site_id !== role.site_id);
  }

  private get roleMissing() {
    return !this.selectedRole || (!(this.siteRoles.length || !!this.newSiteRole.site_id) && this.isConsumer);
  }

  private get filteredSites() {
    return this.sites.filter((s) => {
      return !this.siteRoles.map((sr) => sr.site_id).includes(s.id)
    });
  }

}
