










import { Component, Vue } from "vue-property-decorator";
import {Getter} from "vuex-class";

@Component
export default class Loader extends Vue {
  @Getter private appLoading!: boolean;
}
