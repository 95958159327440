












































import { Component, Vue } from "vue-property-decorator";

@Component
export default class DateRangeSelector extends Vue {
  private tab = 1;
  private rangePosition = 0;
  private timespan: {[key: number]: string} = {
    0: 'day',
    1: 'week',
    2: 'month'
  }

  private modifyDate(rangeType: number, range: number, date = new Date()) {
    switch (rangeType) {
      case 0:
        return new Date(date.setDate(date.getDate() + range));
      case 1:
        return new Date(date.setDate(date.getDate() + (range * 7)));
      case 2:
        return new Date(date.setMonth(date.getMonth() + range));
      default:
        return new Date();
    }
  }

  private get disableForward() {
    if (this.tab !== 2) {
      return new Date(this.range.to).getDate() === new Date().getDate();
    } else {
      return new Date(this.range.to).getMonth() === new Date().getMonth() && new Date(this.range.to).getFullYear() === new Date().getFullYear();
    }
  }

  private get range() {
    const dayAndMonth = 1;
    const week = 7;

    if (this.rangePosition === 0) {
      const fromDay = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - dayAndMonth))
      const fromWeek = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - week))
      const fromMonth = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setMonth(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getMonth() - dayAndMonth))
      const range = {
        from: this.tab === 1 ? fromWeek : this.tab === 2 ? fromMonth : fromDay,
        to: this.modifyDate(this.tab, this.rangePosition, new Date())
      }
      this.$emit('change:range', { range: range, span: this.timespan[this.tab]});
      return range;
    } else if (this.rangePosition < 0) {
      const fromDay = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - dayAndMonth))
      const fromWeek = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - week))
      const fromMonth = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setMonth(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getMonth() - dayAndMonth))
      const range = {
        from: this.tab === 1 ? fromWeek : this.tab === 2 ? fromMonth : fromDay,
        to: this.modifyDate(this.tab, this.rangePosition, new Date())
      }
      this.$emit('change:range', { range: range, span: this.timespan[this.tab]});
      return range;
    } else {
      const fromDay = this.modifyDate(this.tab, this.rangePosition, new Date(new Date().setDate(new Date().getDate() - dayAndMonth)))
      const fromWeek = this.modifyDate(this.tab, this.rangePosition, new Date(new Date().setDate(new Date().getDate() - week)))
      const fromMonth = this.modifyDate(this.tab, this.rangePosition, new Date(new Date().setMonth(new Date().getMonth() - dayAndMonth)))
      const range = {
        from: this.tab === 1 ? fromWeek : this.tab === 2 ? fromMonth : fromDay,
        to: this.modifyDate(this.tab, this.rangePosition, new Date())
      }
      this.$emit('change:range', { range: range, span: this.timespan[this.tab]});
      return range;
    }
  }
}
