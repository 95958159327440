








































import {Component, Prop, Vue} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginService from "@/services/login.service";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
export default class ResendPrompt extends Vue {
  @Prop() private activating!: boolean;
  @Prop() private username!: string;
  private error = '';
  private loading = false;
  private resent = false;

  private async resetPassword() {
    try {
      this.loading = true;
      await LoginService.forgotPassword(this.username);
      this.resent = true;
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }

  private async resendActivationCode() {
    this.loading = true;
    try {
      await LoginService.resendRegistrationCode(this.username);
      this.resent = true;
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }
}
