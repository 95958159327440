













































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
export default class PasswordPrompt extends Vue {
  @Prop() private error!: string;
  @Prop() private activated!: boolean;
  @Prop({default: false}) private isNew!: boolean;
  @Prop() private queryCode!: string;
  @Prop() private loading!: boolean;
  private symbols = /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/;
  private code = '';
  private password = '';
  private repeatedPassword = '';
  private value = {
    repeat: true,
    new: true,
  };
  private passwordValid = true;
  private repeatValid = true;

  private get confirmedDisabled() {
    if (this.isNew) {
      if (this.activated) {
        return !this.code;
      } else {
        return !this.passwordValid || !this.repeatValid || !this.password || !this.repeatedPassword;
      }
    } else {
      return !this.password;
    }
  }

  @Watch('queryCode', {immediate: true})
  private onQueryCodeUpdate() {
    this.code = this.queryCode
  }

  @Watch('code', {immediate: true})
  private onCodeUpdate() {
    this.password = this.code
  }

}
