






















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import EventBus from "@/services/eventbus.service";

@Component
export default class NotificationSnackbar extends Vue {
  @Prop({default: false}) private open!: boolean;
  @Prop({default: 'mdi-content-save'}) private icon!: string;
  @Prop() private text!: string;
  private snackbar = false;

  @Watch('open', {immediate: true})
  private onSnackbarOpen() {
    this.snackbar = this.open;
  }

  private closeSnackbar() {
    EventBus.$emit('closeSnackbar')
  }
}
