
























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import {Asset, Device, MonitoringPoint, Room, Site} from "../types";
import Loader from "@/components/Loader.vue";
import SiteCard from "@/components/SiteCard.vue";
import DeviceMetrics from "@/views/DeviceMetrics.vue";
import AssetDetails from "@/views/AssetDetails.vue";
import SiteService from "@/services/site.service";
import AlertDetails from "@/views/AlertDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
@Component({
  components: {ActionButton, AlertDetails, AssetDetails, DeviceMetrics, SiteCard, Loader}
})
export default class Home extends Vue {
  @Action private showToastError!: any;
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private sites: Site[] = [];
  private siteDetails: { [key: string]: Site | Asset | MonitoringPoint | Room } = {};
  private assetDetails: { [key: string]: Site | Asset  } = {};
  private showDeviceMetrics = false;
  private showSidePanel = false;
  private loadingMetrics = false;

  private showAlertDetails = false;
  private alertDetails: {[key: string]: Site | {[key: string]: Date | string | number}} = {};


  private async mounted() {
    this.toggleLoading(true);
    try {
      this.sites = await SiteService.getSites(this.accountId, undefined, undefined, true);
    } finally {
      this.toggleLoading(false);
    }
  }

  private async showMetricsForAlert(event: { monitoringPointId: number, siteId: number, roomId?: number }) {
    this.loadingMetrics = true;
    try {
      let sites: Site[];
      let room;
      let asset;
      let monitoringPoint: MonitoringPoint | undefined;

      sites = await SiteService.getSites(this.accountId, 4, event.siteId)
      room = sites[0].rooms?.find((r: Room) => r.id === event.roomId);
      room?.assets.forEach((a) => {
        const found = a.monitoringPoints.find((m) => m.id === event.monitoringPointId)
        if (found) {
          asset = a;
          monitoringPoint = found;
        }
      })
      if (!asset || !monitoringPoint) {
        return;
      }
      Vue.set(this, 'siteDetails', {
        device: monitoringPoint && monitoringPoint.device ? monitoringPoint.device : null,
        monitoringPoint: monitoringPoint,
        asset: asset,
        site: sites[0],
        room: room ? room : undefined
      })

      this.showDeviceMetrics = true;
      this.showAlertDetails = false
    } catch (e) {
      const err = e as Error;
      this.showToastError(`Failed to retrieve site: ${err.message}`)
    } finally {
      this.loadingMetrics = false;
    }
  }

  private get filteredSites(): Site[] {
    return this.sites.filter((s: Site) => s.rooms && s.rooms.length && s.rooms.some((r: Room) => r.assets.some((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length && a.monitoringPoints.some((mp) => !!mp.device.id))))
  }
}
