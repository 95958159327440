













































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import {Asset, MonitoringPoint, Room, Site} from "@/types";
import {Getter} from "vuex-class";
@Component({
  components: {ActionButton}
})
export default class RecordCard extends Vue {
  @Prop() private sites!: Site[];
  @Prop() private selectedLocations!: MonitoringPoint[];
  @Prop() private site!: Site;
  @Prop() private selectLocation!: any;
  @Prop({ default: false }) private selectAllDisabled!: boolean;
  private selected: any[] = [];
  private selectAll = false;

  @Watch('selected')
  private onSelect() {
    const selected = [];
    if (this.selected.length) {
      const monitoringPoints = this.selected.flatMap((asset: Asset) => asset.monitoringPoints);
      selected.push(...monitoringPoints)
    }
    this.$emit('select:location', {selected: selected, site: this.site})

  }

  private mounted() {
    if (this.selectedLocations && this.selectedLocations.length) {
      this.selected = this.selectedLocations;
    }
  }

  private selectAllLocations() {
    if (this.selectAll) {
      const selectedRooms = this.roomsWithAssetsWithMonitoringPoints?.flatMap((r: Room) => r.assets.flatMap((a: Asset) => a.monitoringPoints));
      if (selectedRooms) {
        const assets = this.roomsWithAssetsWithMonitoringPoints?.flatMap((r: Room) => r.assets);
        if (assets) {
          this.selected.push(...selectedRooms, ...assets);
        }
      }
    } else {
      this.selected = [];
    }
  }

  private get assetsWithMonitoringPoints() {
    return this.site.assets?.filter((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length)
  }

  private get roomsWithAssetsWithMonitoringPoints() {
    let rooms = [];
    if (this.site.rooms) {
      for (const room of this.site.rooms) {
        let assets = [];
        if (room.assets && room.assets.length) {
          for (const asset of room.assets) {
            const monitoringPoints = [];
            if (asset.monitoringPoints && asset.monitoringPoints.length) {
              for (const mp of asset.monitoringPoints) {
                if (mp.device.id || (!mp.device.id && mp.device.deviceType)) {
                  monitoringPoints.push(mp);
                }
              }
              Vue.set(asset, 'monitoringPoints', monitoringPoints);
              assets.push(asset);
            }
          }
          assets = assets.filter((a) => a.monitoringPoints.length);
          Vue.set(room, 'assets', assets)
          if (room.assets.length) {
            rooms.push(room);
          }
        }
      }
    }
    if (rooms.length) {
      return rooms;
    }
    return [];
  }
}
