









































































import { Component, Vue } from "vue-property-decorator";
import EmailPrompt from "@/views/login/EmailPrompt.vue";
import ActivationPrompt from "@/views/login/ActivationPrompt.vue";
import LoginService from "@/services/login.service";
import ActionButton from "@/components/ActionButton.vue";
import ResendPrompt from "@/views/login/ResendPrompt.vue";

enum ResetSteps {
  EmailPrompt,
  ActivationPrompt,
  Success,
  Resend
}

@Component({
  components: {ResendPrompt, ActionButton, ActivationPrompt, EmailPrompt}
})
export default class ResetFlow extends Vue {
  private username = '';
  private error = '';
  private ResetSteps = ResetSteps;
  private activeStep = ResetSteps.EmailPrompt;
  private info = '';

  private mounted() {
    if (this.$route.params.step === 'ActivationPrompt') {
      this.activeStep = ResetSteps.ActivationPrompt;
      this.info = this.$route.params.info;
    }
  }

  private async checkUser() {
    try {
      const userStatus = await LoginService.getUserStatus(this.username);
      this.activeStep = ResetSteps.ActivationPrompt;
    } catch (err) {
      this.error = (err as Error).message;
    }
  }
  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }
}
