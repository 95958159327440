


























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {Asset, DeviceEvent, MonitoringPoint, Site} from "@/types";
import TextField from "@/components/TextField.vue";
@Component({
  components: {TextField}
})
export default class AssetDetails extends Vue {
  @Getter private accountId!: number;
  @Prop() private selectedAsset!: { [key: string]: any };
  private site: Site = {} as Site;
  private asset: Asset = { id: 0, name: '', siteId: 0, manufacturer: '', assetType: '', configuration: { minTemp: 0, maxTemp: 0, alertThreshold: 0 }, monitoringPoints: [] };
  private assetData: DeviceEvent[] = [];
  private loading = false;

  private mounted() {
    this.loading = true;
    try {
      // TODO: Get asset configuration details
    } finally {
      this.loading = false;
    }
  }

  private get assetType() {
    const types: { [key: string]: string; } = {
      refrigerator: 'mdi-fridge-outline',
      freezer: 'mdi-snowflake',
      room: 'mdi-floor-plan',
    };
    return types[this.selectedAsset.asset.type];
  }
}
