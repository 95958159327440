











































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Getter} from "vuex-class";
import TextField from "@/components/TextField.vue";
import Select from "@/components/Select.vue";
import ActionButton from "@/components/ActionButton.vue";
import GatewayService from "@/services/gateway.service";
@Component({
  components: {ActionButton, Select, TextField}
})
export default class GatewayDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private hasPermission!: any;
  @Prop() private selectedSite!: { [key: string]: any };
  private loading = false;
  private deleteLoading = false;
  private deleteDialog = false;

  private async removeGateway(gatewayId: number) {
    this.deleteLoading = true;
    try {
      await GatewayService.unmountGateway(Number(gatewayId), Number(this.selectedSite.id));
    } finally {
      this.$emit('refresh:site', { site: this.selectedSite });
      this.deleteDialog = false;
      this.deleteLoading = false;
    }
  }

  private get gatewayImg() {
    if (this.selectedSite.gateway && this.selectedSite.gateway.gateway_type && (this.selectedSite.gateway.gateway_type.slug === 'lairdrg1xxbg4' || this.selectedSite.gateway.gateway_type.slug === 'lairdrg1xx5cjm6')) {
      return require('@/assets/laird_gateway.png')
    } else {
      return require('@/assets/hygiplas_gateway.png')
    }
  }


}
