var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pa-4",attrs:{"cols":"12","md":"10","lg":"6"}},[_c('v-card',{staticClass:"px-4 py-6",attrs:{"color":"primary"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('LoginError',{attrs:{"error":_vm.error}}),(_vm.info)?_c('v-alert',{staticClass:"text-left",attrs:{"dense":"","type":"info","color":"error"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.info))])]):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"white--text text-body-1 font-weight-bold"},[_vm._v("Enter your reset code")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-otp-input',{staticClass:"otp",attrs:{"length":"6"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"white--text text-body-1 font-weight-bold"},[_vm._v("Enter a new password")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"passwordForm",model:{value:(_vm.passwordValid),callback:function ($$v) {_vm.passwordValid=$$v},expression:"passwordValid"}},[_c('v-text-field',{staticClass:"custom-field",attrs:{"placeholder":"New password","persistent-placeholder":"","outlined":"","background-color":"#ffffff","append-icon":_vm.value['new'] ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value['new'] ? 'password' : 'text',"rules":[
                    function (v) { return (v && v.length >= 6) || 'Password must be at least 6 characters long.'; },
                    function (v) { return (v && v.toUpperCase() !== v) || 'Password must contain a lowercase character.'; },
                    function (v) { return (v && v.toLowerCase() !== v) || 'Password must contain an uppercase character.'; },
                    function (v) { return (v && /\d/.test(v)) || 'Password must contain a number.'; },
                    function (v) { return (v && _vm.symbols.test(v)) || 'Password must contain a special character.'; } ],"autocomplete":"off"},on:{"click:append":function () { return (_vm.value['new'] = !_vm.value['new']); },"input":function($event){return _vm.$refs.repeatPasswordForm.validate()}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('v-form',{ref:"repeatPasswordForm",model:{value:(_vm.repeatValid),callback:function ($$v) {_vm.repeatValid=$$v},expression:"repeatValid"}},[_c('v-text-field',{staticClass:"custom-field",attrs:{"placeholder":"Repeat new password","persistent-placeholder":"","outlined":"","background-color":"#ffffff","append-icon":_vm.value['repeat'] ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value['repeat'] ? 'password' : 'text',"rules":[
                    function (v) { return !_vm.passwordValid || v === _vm.newPassword || 'Passwords do not match.'; }
                ],"autocomplete":"off"},on:{"click:append":function () { return (_vm.value['repeat'] = !_vm.value['repeat']); },"input":function($event){return _vm.$refs.repeatPasswordForm.validate()}},model:{value:(_vm.repeatedPassword),callback:function ($$v) {_vm.repeatedPassword=$$v},expression:"repeatedPassword"}})],1)],1)],1),_c('v-row',{staticClass:"mt-n4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('a',{staticStyle:{"color":"#f2243f"},on:{"click":_vm.resend}},[_vm._v("Resend reset code?")])]),_c('v-col',{staticClass:"d-flex justify-center"},[_c('ActionButton',{attrs:{"width":"95","text":"Confirm","color":"secondary","loading":_vm.loading,"disabled":_vm.loading || !_vm.passwordValid || !_vm.repeatValid || !_vm.newPassword || !_vm.repeatedPassword},on:{"click":_vm.confirmActivationCode}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }