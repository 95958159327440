

































































































































































































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import TextField from "@/components/TextField.vue";
import {Action, Getter} from "vuex-class";
import {Site} from "@/types";
import Select from "@/components/Select.vue";
import SiteService from "@/services/site.service";
import EventBus from "@/services/eventbus.service";


enum SiteWizardSteps {
  GetStarted = 1,
  SiteSelection = 2,
  ConfirmSite = 3,
  NameSite = 4,
}

@Component({
  components: {Select, TextField, GoogleMap, ActionButton}
})
export default class SiteWizard extends Vue {
  @Getter private accountId!: number;
  @Action private showToastError!: any;
  @Action private goHome!: any;
  @Prop() private sites!: Site[];
  @Prop() private siteStep!: number;
  private showSiteCreation = false;
  private loadingSaveSite = false;
  private SiteWizardSteps = SiteWizardSteps;
  private activeStep: SiteWizardSteps = SiteWizardSteps.GetStarted;
  private site: Partial<Site> = { address: '', coords: [] };
  private selectedSite: any = null;

  @Watch('siteStep', {immediate: true})
  private onStepChange() {
    if (this.siteStep > 0) {
      this.activeStep = this.siteStep;
    }
  }

  private mounted() {
    if (!this.sites.length) {
      this.showSiteCreation = true;
    }
  }

  private stepForward() {

    this.activeStep++;

    if (this.activeStep === SiteWizardSteps.SiteSelection) {
      this.selectedSite = null;
    }
  }

  private stepBack() {
    if (this.activeStep === SiteWizardSteps.NameSite) {
      this.site.name = undefined;
    }
    if (this.activeStep === SiteWizardSteps.SiteSelection) {
      this.site.address = undefined;
    }
    this.activeStep--;
  }

  private selectSite(selectedSite: Site | null) {
    const site = this.sites.find((s: Site) => selectedSite && s.id === selectedSite.id);
    if (site) {
      this.selectedSite = site.id;
      this.site = site;
    } else {
      this.site = { address: '' }
      this.showSiteCreation = true;
    }
  }

  private checkSiteExists() {
    this.site.id ? this.saveAndStepForward() : this.stepForward();
  }

  private setSiteDetails(site: { address: string, coords: number[] }) {
    this.site = {};
    Vue.set(this.site, 'address', site.address);
    Vue.set(this.site, 'coords', site.coords);
    this.$emit('new-site', true)
  }

  private setSiteName(name: string) {
    Vue.set(this.site, 'name', name);
  }

  private async saveAndStepForward() {
    this.loadingSaveSite = true;
    if (this.site.id) {
      this.$emit('complete:site-wizard', this.site)
    } else {
      try {
        if (this.site.name && this.site.coords) {
          const newSite = await SiteService.createSite(this.accountId, {name: this.site.name, coords: this.site.coords, is_site: true });

          Vue.set(this, 'site', newSite)
          EventBus.$emit('showSnackbar', {text: 'Site created', icon: 'mdi-content-save'});
        }
        this.$emit('complete:site-wizard', this.site)
      } catch (e) {
        const err = e as Error;
        this.showToastError(err.message)
      }
    }
    this.loadingSaveSite = false;
  }

  private get siteDetails() {
    return this.site && this.site.address ? this.site.address.split(',') : '';
  }

}
