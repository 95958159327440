import { appAxios } from '../app.axios';
import store from "@/store";
import {Gateway} from "@/types";

export class GatewayService {
  public async getGatewaysForProject() {
    const url = `/project/${store.getters.accountId}/gateways`
    return (await appAxios.get(url)).data;
  }

  public async mountGateway(siteId: number, gatewayId: number): Promise<Gateway> {
    const url = `sites/${siteId}/gateways/${gatewayId}/mount`;
    return (await appAxios.post(url)).data;
  }

  public async getGatewayStatus(gatewayId: number): Promise<{ status: string }> {
    const url = `/gateways/${gatewayId}/status`;
    const response = await appAxios.get(url);
    return (response).data;
  }

  public async unmountGateway(gatewayId: number, siteId: number): Promise<{success: string}> {
    const url = `/gateways/${gatewayId}/unmount`
    return (await appAxios.post(url, { location_id: siteId})).data;
  }
}

export default new GatewayService();


