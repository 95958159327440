
























































import { Component, Vue } from "vue-property-decorator";
import UserCard from "@/components/UserCard.vue";
import UserDetails from "@/views/UserDetails.vue";
import {Role, Site, User} from "@/types";
import {Getter, Mutation} from "vuex-class";
import UserService from "@/services/user.service";
import ActionButton from "@/components/ActionButton.vue";
import Loader from "@/components/Loader.vue";
import SiteService from "@/services/site.service";
@Component({
  components: {Loader, ActionButton, UserDetails, UserCard}
})
export default class UserManagement extends Vue {
  @Getter private hasPermission!: any;
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private showUserDetails = false;
  private userFab = false;
  private users: User[] = [];
  private roles: Role[] = []
  private userDetails: User = {} as User;
  private sites: Site[] = [];
  private updateDetails = 0;

  private async mounted() {
    this.toggleLoading(true);

    try {
      await this.getUsers();
      this.roles = await UserService.getRoles();
      this.sites = await SiteService.getSites(this.accountId, 1);
    } finally {
      this.toggleLoading(false);
    }
  }

  private async getUsers(userId: number | null = null) {
    this.users = await UserService.getUsers();
    if (userId) {
      const user = this.users.find((u) => u.id === userId);
      if (user) {
        this.userDetails = user;
      }
    } else {
      this.userDetails = this.users[0];
      ++this.updateDetails;
    }
  }

}
