








































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Asset, Site, MonitoringPoint, Device, DeviceEvent, Room} from '../types';
import DeviceService from "@/services/device.service";
import {Getter} from "vuex-class";
import Loader from "@/components/Loader.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import EventsService from "@/services/events.service";
@Component({
  components: {DateRangeSelector, Loader}
})
export default class DeviceMetrics extends Vue {
  @Getter private accountId!: number;
  @Prop() private selectedSite!: { [key: string]: any };
  private site: Site = {} as Site;
  private device: { [key: string]: string | number | DeviceEvent } = { id: 0, name: '', status: 'ok', deviceId: '', assetId: 0, gatewayId: 0, siteId: 0, lastEvent: { timestamp: new Date(), temperature: '0', humidity: 0, battery: '80-100%' } };
  private asset: Asset = { id: 0, name: '', siteId: 0, manufacturer: '', assetType: '', configuration: { minTemp: 0, maxTemp: 0, alertThreshold: 0, }, monitoringPoints: [] };
  private monitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private room: Room = {} as Room;
  private deviceData: DeviceEvent[] = [];
  private showDevice = true;
  private range: { range: { from: Date; to: Date; }, span: string } = {
    range: { from: new Date(new Date().setDate(new Date().getDate() + 1)), to: new Date() },
    span: 'week'
  }
  private thresholds: {[key: string]: number} = {};
  private loading = false;
  private tempChartSeries: any = [{
    name: 'Temperature',
    data: []
  }];
  private tempChartOpts: any = {
    noData: {
      text: 'No data for this period',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
    },
    colors: ['#333333'],
    chart: {
      height: 200,
      type: 'line',
      toolbar: {
        show: false,
      }
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    tooltip: {
      marker: {
        show: false
      },
      x: {
        show: true,
        format: "dd MMM yyyy",
        formatter: function (value: string) {
          return new Date(value).toLocaleString('en-GB');
        },
      },
      y: {
        show: false
      }
    },
    xaxis: {
      type: 'datetime',
      min: new Date(new Date(this.range.range.from).setHours(12,0,0,0)).getTime(),
      tooltip: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      tickAmount: 7,
      labels: {
        formatter: function(val: number) {
          return `${val}°C`;
        },
      },
    }
  }
  private humidityChartSeries: any[] = [{
    name: 'Humidity',
    data: []
  }];
  private humidityChartOpts: any = {
    noData: {
      text: 'No data for this period',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
    },
    colors: ['#333333'],
    chart: {
      height: 200,
      type: 'line',
      toolbar: {
        show: false,
      }
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 7,
      labels: {
        formatter: function(value: any, timestamp: string, opts: any) {
          return opts.dateFormatter(new Date(timestamp), 'dd MMM')
        }
      }
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        formatter: function(val: number) {
          return `${val}%`;
        },
      },
    },
    tooltip: {
      marker: {
        show: false
      },
      x: {
        show: true,
        format: "dd MMM yyyy",
        formatter: function (value: string) {
          return new Date(value).toLocaleString('en-GB');
        },
      },
      y: {
        show: false
      }
    },
  }

  @Watch('selectedSite')
  private async onSiteSelect() {
    await this.fetchData();
  }

  private async mounted() {
    await this.fetchData();
    await this.zoomRange(this.range);
    await this.showMarkers(this.tempChartSeries[0].data)
  }

  private showConfiguration() {
    this.showDevice = false;
  }

  private async zoomRange(range: { range: { to: Date; from: Date; }, span: string }) {

    this.range = range;
    if (this.$refs && this.$refs.tempChart) {
      await this.setData({ range: { to: new Date(new Date(this.range.range.to).setHours(23, 59, 59, 999)), from: new Date(new Date(this.range.range.from).setHours(0, 0, 0, 999))}, span: range.span });

      // HACK: For some reason we get script delays if we do not zoom in nextTick (This only impacts when called from component event not initial load)
      await this.$nextTick(() => {
        if (this.$refs.tempChart) {
          (this.$refs.tempChart as any).zoomX(new Date(range.range.from).getTime(), new Date(range.range.to).getTime());
          (this.$refs.humidityChart as any).zoomX(new Date(range.range.from).getTime(), new Date(range.range.to).getTime());
        }
      });
      await this.showMarkers(this.tempChartSeries[0].data)
    }
  }

  private async setRange(range: { range: { to: Date; from: Date; }, span: string }) {
    // await this.setData({ range: { to: new Date(), from: range.range.from}, span: range.span });

    if (this.$refs && this.$refs.tempChart && range.span === 'month') {
      this.tempChartOpts = {
        ...this.tempChartOpts,
        xaxis: {
          type: 'datetime',
          min: new Date(new Date(this.range.range.from).setHours(0, 1, 0, 0)).getTime(),
        }
      }
    this.humidityChartOpts = {
        ...this.humidityChartOpts,
        xaxis: {
          type: 'datetime',
          min: new Date(new Date(this.range.range.from).setHours(0, 1, 0, 0)).getTime(),
        }
      }
    }
  }

  private async setData(range: { range: { to: Date; from: Date; }, span: string }) {
    const data = await EventsService.getHistoricalDeviceEvents(this.accountId, this.selectedSite.monitoringPoint.id, {since: range.range.from.getTime(), before: range.range.to.getTime()});
    Vue.set(this, 'deviceData', data);

    const tempData = data.map((point) => {
      return {x: new Date(point.timestamp).getTime(), y: point.temperature}
    })
    this.tempChartSeries = [
      { name: 'Temperature', data: tempData }
    ];
    const humidityData = this.deviceData.map((point) => {
      return {x: new Date(point.timestamp).getTime(), y: point.humidity }
    })
    this.humidityChartSeries = [
      { name: 'Humidity', data: humidityData }
    ];

    const thresholds = {
      minTemp: Number(this.monitoringPoint.attributes.find((a) => a.key === 'minTemp')?.value),
      maxTemp: Number(this.monitoringPoint.attributes.find((a) => a.key === 'maxTemp')?.value),
    };
    Vue.set(this, 'thresholds', thresholds);

    const min = Math.min(...this.tempChartSeries[0].data.map((d: {x: number, y: number}) => d.y))
    const max = Math.max(...this.tempChartSeries[0].data.map((d: {x: number, y: number}) => d.y))

    this.tempChartOpts = {
      ...this.tempChartOpts,
      yaxis: {
        tickAmount: Math.max(this.getMaxY(max), this.getMaxY(this.thresholds.maxTemp) - (Math.min(this.getMinY(min), this.getMinY(this.thresholds.minTemp)))) / 3,
        min: Math.min(this.getMinY(min), this.getMinY(this.thresholds.minTemp)),
        max: Math.max(this.getMaxY(max), this.getMaxY(this.thresholds.maxTemp)),
        labels: {
          formatter: function (val: number) {
            return `${Math.round(val)}°C`;
          },
        }
      }
    }
  }

  private async fetchData() {
    this.loading = true;
    try {
      this.site = this.selectedSite.site;
      const device = await DeviceService.getDevice(Number(this.selectedSite.device.id));

      if (device?.id) {
        const d = new Date();

        Vue.set(this, 'device', device);
        Vue.set(this, 'room', this.selectedSite.room)
        Vue.set(this, 'asset', this.selectedSite.asset);
        Vue.set(this, 'monitoringPoint', this.selectedSite.monitoringPoint)
        await this.setData({ range: { to: new Date(), from: new Date(d.setDate(d.getDate() - 7))}, span: 'week'})
      }

      const min = Math.min(...this.tempChartSeries[0].data.map((d: {x: number, y: number}) => d.y))
      const max = Math.max(...this.tempChartSeries[0].data.map((d: {x: number, y: number}) => d.y))
      this.tempChartOpts = {
        ...this.tempChartOpts,
        annotations: {
          position: 'back',
          yaxis: [
            {
              y: this.thresholds.minTemp,
              borderColor: '#48a6f1',
              strokeDashArray: 4,
              label: {
                offsetY: 15,
                borderColor: '#48a6f1',
                style: {
                  color: '#ffffff',
                  background: "#48a6f1"
                },
                text: `${this.thresholds.minTemp} °C`
              }
            },
            {
              y: this.thresholds.maxTemp,
              borderColor: '#f2243f',
              strokeDashArray: 4,
              label: {
                offsetY: -3,
                borderColor: '#f2243f',
                style: {
                  color: '#ffffff',
                  background: "#f2243f"
                },
                text: `${this.thresholds.maxTemp} °C`
              }
            }
          ]
        },
        yaxis: {
          ...this.tempChartOpts.yaxis,
          min: Math.min(this.getMinY(min), this.getMinY(this.thresholds.minTemp)),
          max: Math.max(this.getMaxY(max), this.getMaxY(this.thresholds.maxTemp)),
          tickAmount: Math.max(this.getMaxY(max), this.getMaxY(this.thresholds.maxTemp) - Math.min(this.getMinY(min), this.getMinY(this.thresholds.minTemp))) / 3,
          labels: {
            formatter: function (val: number) {
              return `${Math.round(val)}°C`;
            },
          }
        },
        xaxis: {
          type: 'datetime',
          min: new Date(new Date(this.range.range.from).setHours(12, 0, 0, 0)).getTime(),
          tickAmount: this.range.span === 'day' ? 24 : this.range.span === 'month' ? 31 : 8,
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [
              {
                offset: 0,
                color: '#f2243f',
                opacity: 1
              },
              {
                offset: 50,
                color: "#32374b",
                opacity: 1
              },
              {
                offset: 100,
                color: "#48a6f1",
                opacity: 1
              },
            ]
          }
        }
      };


      this.humidityChartOpts = {
        ...this.humidityChartOpts,
        xaxis: {
          type: 'datetime',
          min: new Date(new Date(this.range.range.from).setHours(12, 0, 0, 0)).getTime(),
          tickAmount: this.range.span === 'day' ? 24 : this.range.span === 'month' ? 31 : 8,
        },
        fill: {
          colors: ['#32374b']
        }
      };


    } finally {
      this.loading = false;
    }
  }

  private getMaxY(temperature = this.thresholds.maxTemp) {
    return Math.ceil(temperature / 3) * 3
  }

  private getMinY(temperature =  this.thresholds.minTemp) {
    return Math.floor(temperature / 3) * 3
  }

  private async showMarkers(tempData: {[key: string]: number}[] = []) {
    return new Promise<void>((resolve, reject) => {
      try {
        if (tempData.length === 1) {
          for (const point of tempData) {
            (this.$refs.tempChart as any)?.addPointAnnotation({
              x: point.x,
              y: point.y,
              marker: {
                size: 4,
                strokeOpacity: 0,
                strokeColor: point.y > this.thresholds.maxTemp ? '#f2243f' : point.y < this.thresholds.minTemp ? '#48a6f1' : '#32374b',
                fillOpacity: 0,
                shape: "circle",
                showNullDataPoints: true,
                hover: {
                  size: undefined,
                  sizeOffset: 3
                }
              }
              // image: {
              //   path: point.y < this.thresholds.minTemp || point.y > this.thresholds.maxTemp ? require('@/assets/alert.png') : undefined,
              //   offsetY: -5
              // }
            }, false);
          }
        }
        resolve();
      } catch (err) {
        reject(err)
      }

    });


  }
}

