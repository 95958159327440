
























import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ErrorToast extends Vue {
  @Prop({default: ''}) private heading!: string;
  @Prop({default: ''}) private body!: string;
  @Prop({default: true}) private showToastBoolean!: boolean;
  private mutableShowToastBoolean = true;

  private mounted() {
    setTimeout(() => {
      this.mutableShowToastBoolean = false;
    }, 10000);
  }
}
