






import { Component, Vue } from "vue-property-decorator";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import {Getter} from "vuex-class";
import { debounce } from 'lodash-es';


@Component({components: {VuePdfEmbed}})
export default class PrivacyPolicy extends Vue {
  @Getter private hasPermission!: any;
  private numPages: number | null = null;
  private publicPath = process.env.BASE_URL;
  private resizeObserver: ResizeObserver | undefined;

  private mounted() {
    const rOSM = {
      prevWidth: '', // using width prevents blinking while rendering happens which can change the height
      pdfRef: (this.$refs.pdf as any)
    }
    // Debounce the resizing for performance reasons
    this.resizeObserver = new ResizeObserver(
      debounce((entries: any) => {
        const entry = entries[0];
        const newWidth = entry.contentRect.width;
        const prevWidth = rOSM.prevWidth;
        rOSM.prevWidth = newWidth;
        if (prevWidth !== '' && prevWidth !== newWidth) {
          rOSM.pdfRef.render();
        }
      }, 300));
    this.resizeObserver!.observe(rOSM.pdfRef.$el.parentElement);
  }

  private beforeDestroy() {
    this.resizeObserver!.unobserve((this.$refs.pdf as any).$el);
  }

}
