




























































































































import { Component, Vue } from "vue-property-decorator";
import {Asset, MonitoringPoint, Room, Site} from "@/types";
import Loader from "@/components/Loader.vue";
import RecordCard from "@/components/RecordCard.vue";
import SiteService from "@/services/site.service";
import {Getter, Mutation} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
import DownloadConfiguration from "@/views/DownloadConfiguration.vue";
@Component({
  components: {DownloadConfiguration, ActionButton, RecordCard, Loader}
})
export default class RecordManagement extends Vue {
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private selectedLocations: {[key: number]: (Asset | MonitoringPoint)[]} = {};
  private sites: Site[] = [];
  private selectedFromMenuSite: Site | null = null;
  private selectedSite: Site | null = null;
  private filterMenu = false;
  private showSidePanel = false;
  private selectAllDisabled: {[key: number]: boolean} = {};


  private async mounted() {
    this.toggleLoading(true);
    try {
      this.sites = await SiteService.getSites(this.accountId);
    } finally {
      this.toggleLoading(false);
    }
  }

  private selectLocation(locs: (Room | Asset | MonitoringPoint)[], site: Site) {
    if (site) {
      Vue.set(this.selectedLocations, site.id, locs);
      this.selectedSite = site;
    }
    if (!locs.length) {
      this.selectedSite = null;
      this.sites.forEach((s: Site) => {
        Vue.set(this.selectAllDisabled, s.id, false);
        s.rooms?.forEach((r: Room) => {
          Vue.set(r, 'disabled', false);
          r.assets.forEach((a: Asset) => {
            Vue.set(a, 'disabled', false);
            a.monitoringPoints.forEach((mp: MonitoringPoint) => {
              Vue.set(mp, 'disabled', false);
            })
          })
        })
      })
      return;
    }
    this.sites.forEach((s: Site) => {
      if (this.selectedSite?.id !== s.id) {
        Vue.set(this.selectAllDisabled, s.id, true)
      }
      s.rooms?.forEach((r: Room) => {
        if (r.siteId !== site.id) {
          Vue.set(r, 'disabled', true);
          r.assets.forEach((a: Asset) => {
            Vue.set(a, 'disabled', true);
            a.monitoringPoints.forEach((mp: MonitoringPoint) => {
              Vue.set(mp, 'disabled', true);
            })
          })
        }
      })
    })
  }

  private get filteredSites(): Site[] {
    if (this.selectedFromMenuSite) {
      return [this.selectedFromMenuSite];
    }

    const sites = [];
    for (const site of this.sites) {
      let sitesToHide: number[] = [];
      if (site && site.rooms && site.rooms.length) {
        for (const room of site.rooms) {
          if (room.assets.length) {
            room.assets.forEach((a) => {
              a.siteId = site.id;
              a.monitoringPoints.forEach((mp) => mp.siteId = site.id);
            });
          }
          for (const asset of room.assets) {
            if (!asset.monitoringPoints.length) {
              sitesToHide.push(asset.siteId);
            } else {
              sitesToHide = sitesToHide.filter((s) => s !== asset.siteId);
            }
          }
        }
        if (!sitesToHide.includes(site.id)) {
          sites.push(site);
        }
      }
    }
    return sites;
  }
}
