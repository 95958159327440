




































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Asset, Device, DeviceEvent, MonitoringPoint, Room, Site} from "../types";
import {Getter} from "vuex-class";
import SiteService from "@/services/site.service";

@Component
export default class SiteCard extends Vue {
  @Getter private accountId!: number;
  @Prop() private sites!: Site[];
  private activeDevice: number | null = 0;
  private redAlerts: { [key: number]: Array<{ timestamp: Date, alert_type: string, location_id: number }> } = {};

  private mounted() {
    this.getRedAlerts();
  }

  private clickDevice(site: Site, asset: Asset, monitoringPoint: MonitoringPoint, room: Room) {
    this.activeDevice = monitoringPoint.device ? monitoringPoint.device.id : null;
    const siteDetails = { device: monitoringPoint.device ? monitoringPoint.device : null, monitoringPoint: monitoringPoint, asset: asset, site: site, room: room }
    this.$emit('clicked:monitoring-point', siteDetails);
  }

  private goToDeviceManagement(site: Site) {
    this.$router.push({name: 'devices', params: { siteId: site.id.toString() }})
  }

  private getPositionList(assetId: number, site?: Site, room?: Room) {
    return room?.assets.find((a: Asset) => a.id === assetId)?.monitoringPoints.filter((mp) => mp.device.id);
  }

  private getStatusIndicator(monitoringPoint: MonitoringPoint, site: Site) {
    if (!site.gateway) {
      return { status: 'unavailable', icon: '' }
    }
    if (!monitoringPoint.device.status_id) {
      return { status: 'warning', icon: 'mdi-alert' };
    }
    if (monitoringPoint.device && monitoringPoint.device.lastEvent && monitoringPoint.device.lastEvent.battery === '0-20%') {
      return { status: 'warning', icon: 'mdi-battery-alert-variant' }
    }
    if (this.redAlerts[site.id] && this.redAlerts[site.id].length && this.redAlerts[site.id].map((a) => a.location_id).includes(monitoringPoint.id)) {
      return { status: 'error', icon: `mdi-exclamation-thick` }
    }
    const colors: {[key: number]: { [key:string]: string }} = {
      2: { status: 'success', icon: `mdi-check` },
      3: { status: 'success', icon: `mdi-check` },
      4: { status: 'error', icon: `mdi-exclamation-thick` }
    };
    return colors[monitoringPoint.device.status_id];
  }

  private getAssetType(type: string) {
    const types: { [key: string]: string; } = {
      refrigerator: 'mdi-fridge-outline',
      freezer: 'mdi-snowflake',
      room: 'mdi-floor-plan',
    };
    return types[type];
  }

  private async getRedAlerts() {
    for (const site of this.sites.filter((s: Site) => s.rooms?.length)) {
      if (site.rooms) {
        for (const room of site.rooms) {
          const monitoringPointIds: number[] = [];
          for (const asset of room.assets) {
            asset.monitoringPoints.forEach((mp) => monitoringPointIds.push(mp.id));
          }
          let alerts = await SiteService.getAlertsForSite(site.id, monitoringPointIds);
          if (alerts.length) {
            Vue.set(this.redAlerts, site.id, alerts);
          }
        }
      }
    }
  }

  private filteredRooms(site: Site) {
    return site.rooms?.filter((r: Room) => r.assets.some((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length && a.monitoringPoints.some((mp) => mp.device.id)));
  }
}
