














































































import {Component, Vue, Watch} from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import ActionButton from "@/components/ActionButton.vue";
import UserCard from "@/components/UserCard.vue";
import SuperUserDetails from "@/views/admin/SuperUserDetails.vue";
import {Getter, Mutation} from "vuex-class";
import {User} from "@/types";
import AdminService from "@/services/admin.service";

@Component({
  components: {SuperUserDetails, UserCard, ActionButton, Loader}
})
export default class SuperUserManagement extends Vue {
  @Getter private hasPermission!: any;
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private showUserDetails = false;
  private superUsers: User[] = [];
  private updateDetails = 0;
  private userDetails: User | null = null;
  private userSearch = '';

  @Watch('userSearch')
  private onSearch() {
    if (!this.userSearch) {
      this.userDetails = this.superUsers[0];
    }
  }

  private async mounted() {
    this.toggleLoading(true);

    try {
      await this.getSuperUsers();
    } finally {
      this.toggleLoading(false);
    }
  }

  private async getSuperUsers(userId: number | null = null) {
    this.superUsers = await AdminService.getSuperUsers();
    if (userId) {
      const user = this.superUsers.find((u) => u.id === userId);
      if (user) {
        this.userDetails = user;
      }
    } else {
      this.userDetails = this.superUsers[0];
      ++this.updateDetails;
    }
  }

  private get customSearch() {
    return (item: any, search: string) => {
      if (item) {
        return (item.first_name.toLowerCase() && item.first_name.toLowerCase().indexOf(search) > -1) ||
            (item.last_name.toLowerCase() && item.last_name.toLowerCase().indexOf(search) > -1) ||
            (item.email.toLowerCase() && item.email.toLowerCase().indexOf(search) > -1);
      } else {
        return false;
      }
    };
  }
}
