


























































































import { Component, Vue } from "vue-property-decorator";
import { Action } from 'vuex-class';
import EmailPrompt from "@/views/login/EmailPrompt.vue";
import PasswordPrompt from "@/views/login/PasswordPrompt.vue";
import LoginService from "@/services/login.service";
import ResendPrompt from "@/views/login/ResendPrompt.vue";
import { LoginResponse } from "../../types";

enum LoginSteps {
  EmailPrompt,
  PasswordPrompt,
  NewPasswordPrompt,
  ForgotPassword,
  ResendRegistration,
  Loading
}

@Component({
  components: {ResendPrompt, PasswordPrompt, EmailPrompt}
})
export default class LoginFlow extends Vue {
  private LoginSteps = LoginSteps;
  private activeStep = LoginSteps.EmailPrompt;
  private loginData: LoginResponse = {};
  private loading = false;
  private activated = false;
  private activating = false;
  private challenged = false;
  private isNew = false;
  private username = '';
  private password = '';
  private queryCode = '';
  private error = '';

  @Action private userLogin!: (jwt: string) => Promise<void>;

  private mounted() {
    if (this.$route.query.username) {
      this.username = this.$route.query.username.toString();
      if (this.$route.query.code) {
        this.queryCode = this.$route.query.code.toString();
      }

    }
  }

  private async checkUser() {
    this.loading = true;
    this.challenged = false;
    try {
      const userStatus = await LoginService.getUserStatus(this.username);
      switch (userStatus.status) {
        case 'FORCE_CHANGE_PASSWORD':
          this.activated = false;
          this.isNew = true;
          this.activeStep = LoginSteps.PasswordPrompt;
          break;
        case 'UNCONFIRMED':
          this.activated = false;
          this.isNew = false;
          this.activeStep = LoginSteps.PasswordPrompt;
          break;
        case 'OK':
        default:
          this.activated = true;
          this.isNew = false;
          this.activeStep = LoginSteps.PasswordPrompt;
          break;
      }
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }

  private async login() {
    this.loading = true
    const oldStep: LoginSteps = this.activeStep;
    this.activeStep = LoginSteps.Loading;
    try {
      if (this.challenged) {
        await this.respondToAuthChallenge(this.loginData);
        return;
      }
      const loginResponse = this.activated ? 
        await LoginService.login({username: this.username, password: this.password}) :
        await LoginService.register({username: this.username, code: this.password}); 
      if (loginResponse.challenge === 'NEW_PASSWORD_REQUIRED') {
        this.challenged = true;
        this.activated = false;
        this.isNew = true;
        this.loginData = loginResponse;
        this.activeStep = LoginSteps.NewPasswordPrompt;
        return;
      }
      await this.loginSuccess(loginResponse.token!);
    } catch (err) {
      this.error = (err as Error).message;
      this.activeStep = oldStep;
    } finally {
      this.loading = false;
    }
  }

  private async respondToAuthChallenge(loginResponse: LoginResponse): Promise<void> {
    const oldStep: LoginSteps = this.activeStep;
    this.activeStep = LoginSteps.Loading;
    try {
      const response = await LoginService.respond({
        username: this.username,
        newPassword: this.password,
        challenge: 'NEW_PASSWORD_REQUIRED',
        session: loginResponse.session!,
        requiredAttributes: loginResponse.requiredAttributes || []
      });
      if (response.challenge) {
        throw new Error(`Unexpected challenge response encountered ${response.challenge}`);
      }
      await this.loginSuccess(response.token!);
    } catch (err) {
      this.error = (err as Error).message;
      this.challenged = false;
      this.activeStep = LoginSteps.PasswordPrompt;
    }
  }

  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }

  private async loginSuccess(JWT: string) {
    await this.userLogin(JWT);
  }
}
