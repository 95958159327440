import Axios from 'axios'
import store from '@/store';
import { getAPIUrl } from './util';

export const appAxios = Axios.create();
appAxios.interceptors.response.use((resp: any) => resp, handleError);

appAxios.defaults.baseURL = getAPIUrl();

function handleError(error: any) {

  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch('userLogout');
      return Promise.reject(error);
    } else if (error.response.data && (error.response.data.error || error.response.data.errors)) {
      error.message = error.response.data.error || error.response.data.errors[0];
    }

    if (error.config.noToastFilters) {
      if (Array.isArray(error.config.noToastFilters)) {
        const results = error.config.noToastFilters.map((filter: any) => {
          if ((filter.status && filter.status === error.response.status)
            || (filter.errorMessage && error.message && error.message.includes(filter.errorMessage))) {
            return filter.gives ? filter.gives(error.response) : error.response;
          }
          return undefined;
        })
          .filter((r: any) => r !== undefined);

        if (results.length) {
          return Promise.resolve(results[0]);
        }
      }
    }
  }

  return Promise.reject(error);
}
