import { appAxios } from "./app.axios";

export function generateHourlyTimeSeries(days: number): number[][] {
  const count = days * 24;
  let i = 0;
  const series = [];
  let x = new Date("1 Feb 2022").getTime();
  let lastValue = (Math.random() * 10) + 10;
  while (i < count) {
    lastValue = lastValue + ((Math.random() * 4) - (lastValue / 10))
    series.push([x, lastValue + ((Math.random() * 4) - (lastValue / 10))]);
    x += (86400000 / 24);
    i++;
  }
  return series;
}

export function noDP(val: number) {
  return val.toFixed(0);
}

export function getStage(): string {
  return process.env.VUE_APP_STAGE || 'local';
}

export function getAPIUrl(): string {
  const stage = getStage();
  switch (stage) {
    case 'prod':
      return 'https://refrigeration-api.daizy.io/api/v1.0';
    case 'dev':
      return 'https://refrigeration-test-api.daizy.io/api/v1.0';
    case 'local':
    default:
      return 'http://localhost:8089/api/v1.0';
  }
}

export function daysAgo(days: number) {
  return new Date(new Date().getTime() - (86400000 * days)).toISOString();
}

export async function downloadFile(url: string, defaultFileName: string): Promise<void> {
  await appAxios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then((res) => {
    const file = window.URL.createObjectURL(new Blob([res.data]));

    const docUrl: HTMLAnchorElement = document.createElement('a');
    docUrl.href = file;
    let fileName = defaultFileName;
    const contentDisposition = res.headers['content-disposition'];
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch?.length === 2) {
        fileName = fileNameMatch[1];
      }
    }
    docUrl.setAttribute('download', fileName);
    document.body.appendChild(docUrl);
    docUrl.click();
    docUrl.remove();
    window.URL.revokeObjectURL(url);
  });
}