





































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import {Asset, MonitoringPoint, Site} from "@/types";
import {Getter} from "vuex-class";
@Component({
  components: {ActionButton}
})
export default class AccountCard extends Vue {
  @Getter private accountId!: number;
  @Prop() private account!: any;
  @Prop({default: 'tertiary'}) private color!: string;

  private get displayDate() {
    return new Date(this.account.created_at).toISOString().split('T')[0]
  }
}
