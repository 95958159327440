import '@/plugins/classComponentHooks.ts';
import Vue from 'vue'
import App from './App.vue'
import '@/assets/scss/main.scss';
import '@/plugins/filters';
import router from './router'
import store from './store';
import VueApexCharts from 'vue-apexcharts';
import vuetify from '@/plugins/vuetify';
import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import { appAxios } from './app.axios';
import axios from "axios";
import {Account} from "@/types";

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.component('apex-chart', VueApexCharts);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    installComponents: true,
    language: 'en-gb',
    region: 'GB',
    autobindAllEvents: true
  }
})
Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true,
});

Vue.config.errorHandler = (err: any, vm, info) => {
  if (err?.response?.status === 401) {
    return;
  }
  if (!axios.isCancel(err)) {
    // tslint:disable-next-line
    console.error(err, [vm, info]);
    store.dispatch('showToastError', err.message);
  }
};

Vue.prototype.$imageUrl = (url: string) => {
  const filename = url.split('/');
  if (process.env.VUE_APP_STAGE === 'prod') {
    return `https://images.daizy.io/fit-in/300x300/devices/logos/${filename[filename.length - 1]}`
  } else {
    return `https://images-test.daizy.io/fit-in/300x300/devices/logos/${filename[filename.length - 1]}`
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

