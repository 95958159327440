












import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class LoginError extends Vue {
  @Prop({default: ''}) private error!: string;
}
