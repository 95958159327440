







































































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { DownloadOptions, MonitoringPoint, Site } from '@/types';
import TextField from "@/components/TextField.vue";
import ActionButton from "@/components/ActionButton.vue";
import EventsService from "@/services/events.service";
import {Getter} from "vuex-class";
import Select from "@/components/Select.vue";
import {appAxios} from "@/app.axios";
import { downloadFile } from "../util";
@Component({
  components: {Select, ActionButton, TextField}
})
export default class DownloadConfiguration extends Vue {
  @Getter private accountId!: number;
  @Prop() private site!: Site;
  @Prop() private selectedLocations!: MonitoringPoint[];
  private downloading = false;
  private dates = [new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0]];
  private selectedRange = '';
  private selectedRecordType = '';
  private selectedOutputType = '';
  private times: Array<{ [key: string]: string | boolean }> = [
    { key: '01', value: '01:00'}, { key: '02', value: '02:00'}, { key: '03', value: '03:00'}, { key: '04', value: '04:00'}, { key: '05', value: '05:00'}, { key: '06', value: '06:00'}, { key: '07', value: '07:00'},
    { key: '08', value: '08:00'}, { key: '09', value: '09:00'}, { key: '10', value: '10:00'}, { key: '11', value: '11:00'}, { key: '12', value: '12:00'}, { key: '13', value: '13:00'}, { key: '14', value: '14:00'}, { key: '15', value: '15:00'},
    { key: '16', value: '16:00'}, { key: '17', value: '17:00'}, { key: '18', value: '18:00'}, { key: '19', value: '19:00'}, { key: '20', value: '20:00'}, { key: '21', value: '21:00'}, { key: '22', value: '22:00'}, { key: '23', value: '23:00'}
  ]
  private timeslots: string[] = [];

  @Watch('timeslots', {deep: true})
  private onTimeslotsChanged() {
    localStorage.setItem('recordManagement:pdfTimeSlots', JSON.stringify(this.timeslots));
  }

  private mounted() {
    this.initTimeSlots();
  }

  private initTimeSlots(): void {
    const tss = localStorage.getItem('recordManagement:pdfTimeSlots');
    const ts = tss ? JSON.parse(tss) : [];
    if (ts[0]) {
      this.timeslots.push(ts[0]);
      if (ts[1]) {
        this.timeslots.push(ts[1]);
        if (ts[2]) {
          this.timeslots.push(ts[2]);
        }
      }
    }
  }

  private switchRecordType() {
    if (this.selectedRecordType === 'pdf') {
      this.selectedOutputType = 'pdf'
      this.selectedRange =  'current';
    } else {
      this.selectedOutputType = 'xls';
      this.dates = [new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0]]
    }
  }

  private resetTimeslot(slots: number[]) {
    for (const slot of slots) {
      this.timeslots[slot] = '';
      if (slot === 1) {
        this.times.forEach((t) => {
          t.disabled_slot2 = false
          t.disabled_slot3 = false
        })
      } else if (slot === 2) {
        this.times.forEach((t) => {
          t.disabled_slot3 = false
        })
      }
    }
  }

  private clearTimeslots() {
    this.timeslots = [];
    this.times.forEach((t) => {
      t.disabled_slot2 = false;
      t.disabled_slot3 = false;
    })
  }

  private get max() {
    return new Date().toISOString().split('T')[0];
  }

  private get timeslotOptions() {
    const slot2 = this.times.findIndex((t) => t.key === this.timeslots[0]);
    for (let i = 0; i <= slot2; i++) {
      this.times[i].disabled_slot2 = true;
      this.times[i].disabled_slot3 = true;
    }

    const slot3 = this.times.findIndex((t) => t.key === this.timeslots[1]);
    for (let i = 0; i <= slot3; i++) {
      this.times[i].disabled_slot3 = true;
    }

    return this.times;
  }

  private get timestamps() {
    if (this.selectedRecordType === 'pdf') {
      const date = new Date();
      switch (this.selectedRange) {
        case 'current':
          this.dates[0] = new Date(date.getFullYear(), date.getMonth(), 1).toDateString();
          this.dates[1] = new Date(date.getFullYear(), date.getMonth() + 1, 0).toDateString();
          break;
        case 'previous':
          this.dates[0] = new Date(date.getFullYear(), date.getMonth() - 1, 1).toDateString();
          this.dates[1] = new Date(date.getFullYear(), date.getMonth(), 0).toDateString();
          break;
        case 'six_months':
          this.dates[0] = new Date(date.getFullYear(), date.getMonth() - 6, 1).toDateString();
          this.dates[1] = new Date(date.getFullYear(), date.getMonth(), 0).toDateString();
          break;
      }
    }
    let dates = []
    if (this.dates[0]) {
      dates.push(this.dates[0])
    } else {
      dates.push('Select a date')
    }
    if (this.dates[1]) {
      dates.push(this.dates[1])
    } else {
      dates.push('Select a date')
    }
    if (dates.every((d) => d !== 'Select a date')) {
      dates.sort((x: string, y: string) => {
        return new Date(x).getTime() - new Date(y).getTime();
      })
      return dates;
    }
    return dates;
  }

  private async exportData() {
    this.downloading = true;
    
    try {
      const since = new Date(this.timestamps[0]);
      since.setHours(0, 0, 0, 0);
      const before = new Date(this.timestamps[1]);
      before.setHours(23, 59, 59, 999);
      const opts : DownloadOptions = {
        before,
        monitoringPointIds: this.selectedLocations.map((m)=>m.id),
        since,
        siteId: this.site.id,
        type: this.selectedRecordType,
        format: this.selectedOutputType,
        ...(this.timeslots[0] && { hour1: Number(this.timeslots[0]) }),
        ...(this.timeslots[1] && { hour2: Number(this.timeslots[1]) }),
        ...(this.timeslots[2] && { hour3: Number(this.timeslots[2]) }),
        timezoneOffsetMS: (since.getTimezoneOffset() * 60000)
      }
      const url = await EventsService.getRecordsDownloadURL(this.accountId,opts);

      const fileName = (new Date()).toISOString().split('T')[0];
      
      if (this.selectedRecordType === 'pdf') {
        await downloadFile(url, `${fileName}_report.pdf`);
      } else {
        if (this.selectedOutputType === 'csv') {
          await downloadFile(url, `${fileName}_records.csv`);
        } else {
          await downloadFile(url, `${fileName}_records.xlsx`);
        }
      }
    } catch (error) {
      const err = error as Error;

      throw err;
    } finally {
      this.downloading = false;
    }

  }
}
