






































































import {Component, Prop, Vue} from "vue-property-decorator";
import SiteDeviceCard from "@/components/SiteDeviceCard.vue";
import {Asset, MonitoringPoint, Room, Site} from "@/types";
import DeviceDetails from "@/views/DeviceDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
import SiteService from "@/services/site.service";
import {Getter, Mutation} from "vuex-class";
import Loader from "@/components/Loader.vue";
import GatewayDetails from "@/views/GatewayDetails.vue";
@Component({
  components: {GatewayDetails, Loader, ActionButton, DeviceDetails, SiteDeviceCard}
})
export default class DeviceManagement extends Vue {
  @Getter private hasPermission!: any;
  @Getter private accountId!: number;
  @Getter private appLoading!: boolean;
  @Mutation private toggleLoading!: any;
  private sites: Site[] = [];
  private showSidePanel = false;
  private showDeviceDetails = false;
  private showGatewayDetails = false;
  private siteDetails: { [key: string]: any } = {};
  private activeDeviceId = 0;
  private activeGatewayId = 0;

  private async mounted() {
    await this.fetchData();
  }

  private async fetchData() {
    this.toggleLoading(true);
    try {
      this.activeDeviceId = 0;
      this.activeGatewayId = 0;
      this.showDeviceDetails = false;
      this.showGatewayDetails = false;
      this.showSidePanel = false;

      this.sites = await SiteService.getSites(this.accountId);
      this.siteDetails = {};
    } finally {
      this.toggleLoading(false);
    }
  }

  private async refreshSite(details: { [key: string]: Site | Room | Asset | MonitoringPoint }) {
    this.siteDetails = {};
    this.activeDeviceId = 0;
    this.activeGatewayId = 0;
    this.showDeviceDetails = false;
    this.showGatewayDetails = false;
    this.showSidePanel = false;

    if (!details.site) {
      return;
    }
    const index = this.sites.findIndex((s) => s.id === details.site.id);
    const site = await SiteService.getSites(this.accountId, undefined, details.site.id);
    this.sites.splice(index, 1, site[0]);

    if (details.device && details.device.id) {
      Vue.set(this.siteDetails, 'site', site[0]);
      Vue.set(this.siteDetails, 'device', details.device);
      Vue.set(this.siteDetails, 'room', this.siteDetails.site.rooms.find((r: Room) => r.id === details.room.id));
      Vue.set(this.siteDetails, 'asset', this.siteDetails.room.assets.find((a: Asset) => a.id === details.asset.id));
      Vue.set(this.siteDetails, 'monitoringPoint', this.siteDetails.room.assets.find((a: Asset) => a.id === details.asset.id)?.monitoringPoints.find((m: MonitoringPoint) => m.id === details.monitoringPoint.id))

      this.showSidePanel = true;
      this.activeDeviceId = details.device.id;
    }
  }
}
