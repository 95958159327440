









































































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DeviceService from "@/services/device.service";
import AppDatasetService from "@/services/app.dataset.service";
import {Asset, Device, DeviceEvent, MonitoringPoint, Room, Site} from "@/types";
import {Action, Getter} from "vuex-class";
import TextField from "@/components/TextField.vue";
import Select from "@/components/Select.vue";
import ActionButton from "@/components/ActionButton.vue";
import SiteService from "@/services/site.service";
@Component({
  components: {ActionButton, Select, TextField}
})
export default class DeviceDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private hasPermission!: any;
  @Action private showToastError: any;
  @Prop() private selectedSite!: { [key: string]: any };
  @Prop() private selectedRoom!: Room;
  private site: Site = {} as Site;
  private device: { [key: string]: string | number | DeviceEvent } = { id: 0, name: '', status: 'ok', deviceId: '', assetId: 0, gatewayId: 0, siteId: 0, lastEvent: {} as DeviceEvent };
  private asset: Asset = { id: 0, name: '', siteId: 0, manufacturer: '', assetType: '', configuration: { minTemp: 0, maxTemp: 0, alertThreshold: 0 }, monitoringPoints: [] };
  private monitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private room: Room | null = null;
  private validTemps = false;
  private loading = false;
  private valid = false;
  private assetDialog = false;
  private roomDialog = false;
  private deleteLoading = false;
  private deleteDialog = false;
  private saveLoading = false;
  private selectedAlertThreshold = 15;
  private configuration: any = {};
  private isDirty: {[key: string]: { name: string, is_monitoring_point: boolean }} = {}
  private names: {[key: string]: string} = {}
  private assetTypes: { [key: string]: { icon: string } } = {
    refrigerator: { icon: 'mdi-fridge-outline' },
    freezer: { icon: 'mdi-snowflake' },
    room: { icon: 'mdi-floor-plan' },
  };
  private alertThresholds: Array<{ text: string, value: number }> = [
    { text: '0 min', value: 0 },
    { text: '15 min', value: 15 },
    { text: '30 min', value: 30 },
    { text: '1 hr', value: 60 },
    { text: '2 hr', value: 120 }
  ]

  @Watch('configuration.minTemp')
  @Watch('configuration.maxTemp')
  private async onConfigChange() {
    await this.$nextTick();
    if (this.$refs && this.$refs.tempForm) {
      (this.$refs.tempForm as any).validate();
    }
  }

  @Watch('selectedSite')
  private async onSiteSelect() {
    await this.fetchData();
  }

  private async mounted() {
    await this.fetchData();
  }

  private async fetchData() {
    this.loading = true;
    try {
      this.site = this.selectedSite.site;
      const device = await DeviceService.getDevice(Number(this.selectedSite.device.id));
      if (device?.id) {
        Vue.set(this, 'device', device);
        Vue.set(this, 'asset', this.selectedSite.asset);
        Vue.set(this, 'monitoringPoint', this.selectedSite.monitoringPoint)
        Vue.set(this, 'room', this.selectedSite.room);

        this.configuration = {
          minTemp: this.monitoringPoint?.attributes.find((a) => a.key === 'minTemp')?.value,
          maxTemp: this.monitoringPoint?.attributes.find((a) => a.key === 'maxTemp')?.value,
        };
        this.selectedAlertThreshold = Number(this.monitoringPoint?.attributes.find((a) => a.key === 'alertThreshold')?.value);
      }
    } finally {
      this.loading = false;
    }
  }

  private setValue(obj: string | number, value: string | number, isMonitoringPoint?: boolean) {
    if (typeof obj !== 'string') {
      Vue.set(this.isDirty, obj, {name: value, is_site: false, is_monitoring_point: isMonitoringPoint});
    } else {
      Vue.set(this.configuration, obj, value);
    }
  }

  private async saveConfiguration() {
    this.saveLoading = true;

    try {
      await SiteService.updateSite(this.monitoringPoint.id, {
        attributes: [
          {key: 'minTemp', value: this.configuration.minTemp},
          {key: 'maxTemp', value: this.configuration.maxTemp},
          {key: 'alertThreshold', value: this.selectedAlertThreshold},
        ]
      })
      this.$emit('refresh:site', {site: this.site, asset: this.asset, monitoringPoint: this.monitoringPoint, device: this.device, room: this.room})
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.saveLoading = false;
    }

  }

  private async removeDevice(deviceId: string | number | DeviceEvent) {
    this.deleteLoading = true;

    try {
      await DeviceService.removeDevice(Number(deviceId));
      this.room?.assets.forEach((a) => {
        const foundIndex = a.monitoringPoints.findIndex((mp) => mp.id === this.monitoringPoint.id);
        if (foundIndex > -1) {
          a.monitoringPoints.splice(foundIndex, 1);
        }
      })
    } finally {
      this.$emit('refresh:site', {})
      this.deleteDialog = false;
      this.deleteLoading = false;
    }
  }

  private getStatusIndicator(device: Device) {
    if (device && device.status_id) {
      const colors: { [key: number]: { [key: string]: string } } = {
        2: {status: 'success', icon: 'mdi-check-circle'},
        3: {status: 'success', icon: 'mdi-check-circle'},
        4: {status: 'error', icon: 'mdi-alert'}
      };
      return colors[device.status_id];
    } else {
      return {status: 'warning', icon: 'mdi-alert'};
    }
  }

  private async saveNames() {
    this.saveLoading = true;
    try {
      for (const id in this.isDirty) {
        if (this.isDirty[id]) {
          await SiteService.updateSite(Number(id), {
            name: this.isDirty[id].name,
            is_site: false,
            is_monitoring_point: this.isDirty[id].is_monitoring_point,
          })
        }
      }
      this.assetDialog = false;
      this.roomDialog = false;
      this.isDirty = {};

      this.$emit('refresh:site', {site: this.site, asset: this.asset, monitoringPoint: this.monitoringPoint, device: this.device, room: this.room})
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.saveLoading = false;
    }

  }
}
