

































































































import {Asset, Site} from "@/types";
import {Component, Prop, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";


@Component
export default class AlertDetails extends Vue {
  @Prop() private alerts!: any;
  @Prop() private site!: Site;
  @Prop() private loadingMetrics!: boolean;

  private alertType(type: string) {
    switch (type) {
      case 'nodata_alert': return 'No data received';
      case 'temperature_alert': return 'Temperature outside thresholds';
      case 'battery_alert': return 'Battery low';
      case 'gateway_offline_alert': return 'Gateway is offline';
    }
  }

  private getName(id: number) {
    if (this.site.id === id) {
      return 'Gateway';
    }
    const room = this.site.rooms?.find((r) => r.assets?.some((a) => a.monitoringPoints.some((mp) => mp.id === id)));
    const asset = this.site.rooms?.find((r) => r.assets?.find((a) => a.monitoringPoints.find((mp) => mp.id === id)))?.assets.find((a) => a.monitoringPoints.find((mp) => mp.id === id));
    return `${room?.name}⠀›⠀${asset?.name}`;
  }

  private get deviceAlerts() {
    return this.alerts.filter((a: { alert_type: string; timestamp: string; location_id: number, room_id: number }) => {
      const room = this.site.rooms?.find((r) => r.id === a.room_id);
      const monitoringPointIds = room?.assets?.flatMap((asset) => asset.monitoringPoints.map((mp) => mp.id));
      if (a.alert_type !== 'gateway_offline_alert' && monitoringPointIds?.includes(a.location_id)) {
        return a;
      }
    });
  }

  private get gatewayAlerts() {
    return this.alerts.filter((a: { alert_type: string; timestamp: string; location_id: number }) => a.alert_type === 'gateway_offline_alert');
  }

}
