import Vue from 'vue';

Vue.filter('truncate', (text: string, maxLength: number) => {
  if (!text) {
    return null;
  }
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
});
