
export function debounce<T extends (...args: any[]) => void>(cb: T, wait = 20) {
  let h: any = 0;
  const callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return (callable as any) as T;
}

export function throttle<T extends (...args: any[]) => void>(cb: T, wait = 3000){
  let throttled = false;
  return (...args: any) =>{
    if (!throttled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cb.apply(this, args);
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, wait);
    }
  }
}