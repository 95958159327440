




















































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
import store from "@/store";
import AdminService from "@/services/admin.service";
@Component({
  components: {ActionButton}
})
export default class CustomerAccountDetails extends Vue {
  @Action private showToastError!: any;
  @Getter private accountId!: number;
  @Prop() private selectedAccount!: any;
  private suspendLoading = false;
  private deleteLoading = false;
  private suspendDialog = false;
  private deleteDialog = false;
  private loading = false;

  private async switchAccount() {
    store.commit('setAccountId', {accountId: this.selectedAccount.daizy_project_id, accountCode: this.selectedAccount.account_code});
    store.commit('setAccountManaged', true)
  }

  private async toggleSuspendAccount() {
    this.suspendLoading = true;
    try {
      const suspendedDate = this.selectedAccount.suspended_at ? null : new Date();
      await AdminService.toggleSuspendAccount(this.selectedAccount.daizy_project_id, suspendedDate);
      store.commit('setAccountId', {accountId: null, accountCode: null});
      store.commit('setAccountManaged', false);
      if (this.$route.name !== 'customer-accounts') {
        await this.$router.replace({name: 'customer-accounts'})
      }
      this.$emit('refresh:accounts', this.selectedAccount.daizy_project_id);
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message);
    } finally {
      this.suspendLoading = false;
      this.suspendDialog = false;
    }
  }

  private async terminateAccount() {
    this.deleteLoading = true;
    try {
      await AdminService.terminateAccount(this.selectedAccount.daizy_project_id);
      store.commit('setAccountId', {accountId: null, accountCode: null});
      store.commit('setAccountManaged', false);
      if (this.$route.name !== 'customer-accounts') {
        await this.$router.replace({name: 'customer-accounts'})
      }
      this.$emit('remove:account', this.selectedAccount.daizy_project_id);
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message);
    } finally {
      this.deleteLoading = false;
      this.deleteDialog = false;
    }
  }

  private get suspendedDatetime() {
    return new Date(this.selectedAccount.suspended_at).toLocaleString('en-GB')
  }
}
