



















import { Component, Vue } from "vue-property-decorator";

@Component
export default class NotFound extends Vue {
  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }
}
