












































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class TextField extends Vue {
  @Prop() private hint!: string;
  @Prop() private label!: string;
  @Prop() private value!: string | number;
  @Prop() private type!: string;
  @Prop() private append!: string;
  @Prop() private appendIcon!: string;
  @Prop() private editable!: boolean;
  @Prop({default: false}) private hideDetails!: boolean;
  @Prop() private rules!: any;
  @Prop({default: false}) private disabled!: boolean;
  private input = '';
  private isDisabled = false;

  private mounted() {
    this.isDisabled = this.disabled;
  }
  @Watch('value', { immediate: true })
  private onValueChange(newVal: string) {
    this.input = newVal;
  }


}
