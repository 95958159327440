














import { Component, Vue } from "vue-property-decorator";
import Layout from "@/views/Layout.vue";
import LoginFlow from "@/views/login/LoginFlow.vue";
import ActivationFlow from "@/views/login/ResetFlow.vue";
import {Action, Getter, Mutation} from "vuex-class";
import EventBus from "@/services/eventbus.service";
import NotificationSnackbar from "@/components/NotificationSnackbar.vue";
import store from "./store";
import ErrorToast from "@/components/ErrorToast.vue";

@Component({
  components: {ErrorToast, NotificationSnackbar, ActivationFlow, LoginFlow, Layout}
})
export default class App extends Vue {
  @Getter private toastErrors!: {[id: string]: string};
  @Mutation private removeToastErrorMessage: any;
  private snackbarOpened = false;
  private snackbarText = '';
  private snackbarIcon = '';

  private created () {
    EventBus.$on('showSnackbar', this.showSnackbar);
    EventBus.$on('closeSnackbar', () => { this.snackbarOpened = false; });
  }

  private destroyed() {
    EventBus.$off('showSnackbar', () => { this.snackbarOpened = false; });
  }

  private checkRemoveNotification(show: boolean, key: string): void {
    if (!show) {
      this.removeToastErrorMessage(key);
    }
  }
  private showSnackbar(details: any) {
    this.snackbarOpened = true;
    this.snackbarText = details.text;
    this.snackbarIcon = details.icon;
    setTimeout(() => {
      this.snackbarOpened = false;
    }, 3000);
  }
}
