import {Account, User} from "@/types";
import {appAxios} from "@/app.axios";

export class AdminService {
  public async getSuperUsers(): Promise<User[]> {
    const url = `/admin/users`;
    return (await appAxios.get(url)).data;
  }
  public async addSuperUser(user: { first_name: string; last_name: string; email: string }): Promise<User> {
    const url = `/admin/users/add`;
    return (await appAxios.post(url, user)).data;
  }
  public async updateSuperUser(userId: number, user: { first_name: string; last_name: string; }): Promise<User> {
    const url = `/admin/users/${userId}`;
    return (await appAxios.put(url, user)).data;
  }
  public async removeSuperUser(userId: number): Promise<{ success: boolean }> {
    const url = `/admin/users/${userId}`;
    return (await appAxios.delete(url)).data;
  }
  public async getAccounts(): Promise<Account[]> {
    const url = `/admin/accounts`;
    return (await appAxios.get(url)).data;
  }
  public async toggleSuspendAccount(accountId: number, suspendedDate: Date | null): Promise<void> {
    const url = `/admin/accounts/${accountId}/suspend`;
    return (await appAxios.put(url, { suspended_at: suspendedDate }))
  }
  public async terminateAccount(accountId: number): Promise<void> {
    const url = `/admin/accounts/${accountId}/terminate`;
    return (await appAxios.delete(url))
  }
}

export default new AdminService();