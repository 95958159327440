import { render, staticRenderFns } from "./ActionButton.vue?vue&type=template&id=efe191d4&scoped=true&"
import script from "./ActionButton.vue?vue&type=script&lang=ts&"
export * from "./ActionButton.vue?vue&type=script&lang=ts&"
import style0 from "./ActionButton.vue?vue&type=style&index=0&id=efe191d4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efe191d4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
