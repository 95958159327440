





























































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Asset, Device, Gateway, MonitoringPoint, Room, Site} from "@/types";
import TextField from "@/components/TextField.vue";
import SiteService from "@/services/site.service";
import {Action, Getter} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
@Component({
  components: {ActionButton, TextField}
})
export default class SiteDeviceCard extends Vue {
  @Getter private hasPermission!: any;
  @Action private showToastError!: any;
  @Prop() private site!: Site;
  @Prop() private activeDeviceId!: number;
  @Prop() private activeGatewayId!: number;
  private deleteDialog = false;
  private deleteLoading = false;
  private dialog = false;
  private gatewaySelected = false;
  private valid = false;
  private saveLoading = false;
  private isDirty: {[key: string]: { name: string, isSite: boolean }} = {}
  private names: {[key: string]: string} = {}


  private clickDevice(site: Site, asset: Asset, monitoringPoint: MonitoringPoint, room?: Room) {
    const siteDetails = {
      device: monitoringPoint.device ? monitoringPoint.device : null,
      monitoringPoint: monitoringPoint,
      asset: asset,
      site: site,
      room: room
    };
    this.gatewaySelected = false;
    this.$emit('clicked:device', siteDetails);
  }

  private getPositionList(assetId: number, site?: Site, room?: Room) {
    return room?.assets.find((a: Asset) => a.id === assetId)?.monitoringPoints.filter((mp) => mp.device.id);
  }

  private getStatusIndicator(device: Device) {
    if (!device.status_id) {
      return { status: 'warning', icon: 'mdi-exclamation-thick' };
    }
    const colors: {[key: number]: { [key:string]: string }} = {
      2: { status: 'success', icon: 'mdi-check'},
      3: { status: 'success', icon: 'mdi-check' },
      4: { status: 'error', icon: 'mdi-exclamation-thick' }
    };

    return colors[device.status_id];
  }

  private setValue(id: string, name: string, isSite: boolean) {
    Vue.set(this.isDirty, id, {name, isSite});
  }

  private clickGateway(site: Site) {
    if (site.gateway) {
      console.log(site)
      this.gatewaySelected = true;
      this.$emit('clicked:gateway', site)
    }
  }

  private async saveNames() {
    this.saveLoading = true;
    try {
      for (const id in this.isDirty) {
        if (this.isDirty[id]) {
          await SiteService.updateSite(Number(id), {
            name: this.isDirty[id].name,
            is_site: this.isDirty[id].isSite
          })
        }
      }
      this.dialog = false;
      this.isDirty = {};
      this.$emit('refresh:sites')
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.saveLoading = false;
    }

  }

  private gatewayThumbnail(gateway: {[key: string]: any}) {
    if (gateway && (gateway.gateway_type.slug === 'lairdrg1xxbg4' || gateway.gateway_type.slug === 'lairdrg1xx5cjm6')) {
      return require('@/assets/laird_gateway_thumbnail.png')
    } else {
      return require('@/assets/gateway_thumbnail.png')
    }
  }

  private async deleteSite(siteId: number) {
    this.deleteLoading = true;
    try {
      await SiteService.deleteSite(siteId);
      this.$emit('refresh:sites')
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message);
    } finally {
      this.deleteLoading = false;
      this.deleteDialog = false;
    }
  }

  private getAssetType(type: string) {
    const types: { [key: string]: string; } = {
      refrigerator: 'mdi-fridge-outline',
      freezer: 'mdi-snowflake',
      room: 'mdi-floor-plan',
    };
    return types[type];
  }

  private filteredRooms(site: Site) {
    return site.rooms?.filter((r: Room) => r.assets.some((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length && a.monitoringPoints.some((mp) => !!mp.device.id)));
  }

  private get siteHasSensors() {
    return !!this.site && !!this.site.rooms && !!this.site.rooms.length && this.site.rooms.some((r: Room) => !!r.assets && r.assets.some((a) => a.monitoringPoints.some((mp) => !!mp.device)));
  }

  private get siteHasGateway() {
    return !!this.site && !!this.site.gateway
  }

  private get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 'max-width: 38vw';
      case 'sm': return 'max-width: 60vw';
      case 'md': return 'max-width: 24vw';
      default: return 'max-width: 100vw';
    }
  }

}
