import { render, staticRenderFns } from "./Setup.vue?vue&type=template&id=0e10c0a6&"
import script from "./Setup.vue?vue&type=script&lang=ts&"
export * from "./Setup.vue?vue&type=script&lang=ts&"
import style0 from "./Setup.vue?vue&type=style&index=0&id=0e10c0a6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
installComponents(component, {VCard,VCol,VIcon,VRow,VStepper,VStepperContent,VStepperItems})
