






























































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import {Action, Getter, Mutation} from "vuex-class";
import SiteService from "@/services/site.service";
import {Asset, MonitoringPoint, Site} from "@/types";
import store from "@/store";
@Component({
  components: {Loader}
})
export default class Layout extends Vue {
  @Action private userLogout!: any;
  @Getter private appLoading!: boolean;
  @Getter private hasPermission!: any;
  @Getter private accountId!: number;
  @Getter private accountCode!: string;
  @Mutation private setDebugMode!: any;
  @Mutation private toggleLoading!: any;
  private activeBottomMenu = 0;
  private activeSubMenu = 0;
  private sites: Site[] = [];
  private drawer = false;
  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }

  private clearAccountId() {
    store.commit('setAccountId', {accountId: null, accountCode: null});
    store.commit('setAccountManaged', false);
    if (this.$route.name !== 'customer-accounts') {
      this.$router.replace({name: 'customer-accounts'})
    }
  }
}

