import { appAxios } from '../app.axios';
import { LoginResponse } from '../types';


export class LoginService {
  public async getUserStatus(username: string): Promise<{ status: string }> {
    return (await appAxios.get(`/auth/user/status?username=${username}`)).data;
  }

  public async login(credentials: { username: string; password: string; }): Promise<LoginResponse> {
    return (await appAxios.post(`/auth/login`, credentials)).data;
  }

  public async register(credentials: { username: string; code: string; }): Promise<LoginResponse> {
    return (await appAxios.post(`/auth/register`, credentials)).data;
  }

  public async respond(credentials: { username: string; challenge: string; session: string; newPassword: string, requiredAttributes?: string[] }): Promise<LoginResponse> {
    return (await appAxios.post(`/auth/response`, credentials)).data;
  }

  public async forgotPassword(username: string): Promise<{ success: boolean }> {
    return (await appAxios.post('/auth/forgotPassword', { username })).data;
  }

  public async confirmForgotPassword(newCredentials: {[key: string]: string}): Promise<{ success: boolean }> {
    return (await appAxios.post('/auth/confirmForgotPassword', newCredentials)).data;
  }

  public async resendRegistrationCode(username: string): Promise<{ success: boolean }> {
    return (await appAxios.post('/auth/resendRegistration', { username })).data;
  }
}

export default new LoginService();
