

































































































































































































import {Role, Site, User} from "@/types";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Select from "@/components/Select.vue";
import SiteService from "@/services/site.service";
import {Getter} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
import UserService from "@/services/user.service";
@Component({
  components: {ActionButton, Select}
})
export default class UserDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private user!: any;
  @Prop() private roles!: Role[];
  @Prop() private sites!: Site[];
  @Prop() private selectedUser!: User;
  private deleteLoading = false;
  private deleteDialog = false;
  private loading = false;
  private isConsumer = false;
  private validName = true;
  private showEditName = false;
  private userDetails: { firstName: string; lastName: string; alertsEnabled: boolean } = {
    firstName: '',
    lastName: '',
    alertsEnabled: true
  };
  private saving = false;
  private selectedRole = 0;
  private addingSiteRole = false;
  private editingSiteRole: {[key: number]: boolean} = {};
  private newSiteRole: {site_id: number; roles: number[]} = { site_id: 0, roles: [] };
  private siteRoles: Array<{site_id: number; roles: number[]}> = [];

  @Watch('selectedRole')
  private onRoleChange(newVal: number) {
    if (newVal === 1) {
      this.isConsumer = false;
      Vue.set(this, 'siteRoles', [])
    } else {
      this.isConsumer = true;
    }
  }

  private async mounted() {
    if (this.selectedUser.sites && this.selectedUser.sites.length) {
      this.siteRoles = this.selectedUser.sites;
      this.selectedRole = 2;
      this.isConsumer = this.selectedRole === 2;
    } else if (this.selectedUser.roles?.includes(1)) {
      this.selectedRole = 1;
    }
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.selectedUser.alerts_enabled
    }
    this.isConsumer = !!this.selectedUser.sites?.length;

  }

  private async addSiteRole() {
    this.addingSiteRole = true;
  }

  private cancelNewSiteRole() {
    this.addingSiteRole = false;
    this.newSiteRole = { site_id: 0, roles: [] };
  }

  private async saveEditUser() {
    this.saving = true;
    if (this.newSiteRole.site_id) {
      Vue.set(this.newSiteRole, 'roles', [2]);

      this.siteRoles.push(this.newSiteRole)
    }
    if (this.selectedUser.id) {
      const payload = {
        user: {
          first_name: this.userDetails.firstName,
          last_name: this.userDetails.lastName,
          alerts_enabled: this.userDetails.alertsEnabled
        },
        roles: this.isConsumer ? null : [1],
        sites: this.isConsumer ? this.siteRoles : [],
      }

      await UserService.updateUser(this.accountId, this.selectedUser.id, payload);

      this.addingSiteRole = false;
      this.showEditName = false;
      this.newSiteRole = { site_id: 0, roles: [] };

      this.$emit('refresh:user', this.selectedUser.id)
      this.saving = false;
    }
  }

  private resetName() {
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.userDetails.alertsEnabled
    }
  }

  private removeSiteRoles(role: { site_id: number, roles: number[] }) {
    this.siteRoles = this.siteRoles.filter((sr) => sr.site_id !== role.site_id);
  }

  private async removeUser(userId: number) {
    this.deleteLoading = true
    try {
      await UserService.removeUser(this.accountId, userId)
      this.deleteDialog = false;
    } finally {
      this.$emit('remove:user')
      this.deleteLoading = false;
    }
  }

  private get disableSave(): boolean {
    return !(this.siteRoles.length || !!this.newSiteRole.site_id) && !!this.isConsumer;
  }

  private get filteredSites() {
    return this.sites.filter((s) => !this.siteRoles.map((sr) => sr.site_id).includes(s.id))
  }
}
