import { DeviceEvent, DownloadOptions } from '../types';
import {appAxios} from "../app.axios";


export class EventsService {
  public async getHistoricalDeviceEvents(accountId: number, locationId: number, timeFilters: {[key: string]: string | number} = {}): Promise<DeviceEvent[]> {
    const { since, before } = timeFilters;
    const url = `/projects/${accountId}/locations/${locationId}/events?since=${since}&before=${before}`
    return (await appAxios.get(url)).data;
  }

  public async getRecordsDownloadURL(accountId: number, opts: DownloadOptions) {
    let url = `/download/${accountId}/url?type=${opts.type}`;
    if (opts.siteId){
      url = url + `&siteId=${opts.siteId}`;
    }
    if (opts.monitoringPointIds){
      url = url + `&monitoringPointIds=${opts.monitoringPointIds.join(',')}`;
    }
    if (opts.assetLocationIds){
      url = url + `&assetLocationIds=${opts.assetLocationIds.join(',')}`;
    }
    if (opts.since){
      url = url + `&since=${opts.since.valueOf()}`;
    }
    if (opts.before){
      url = url + `&before=${opts.before.valueOf()}`;
    }
    if (opts.format === 'xls') {
      url = url + `&xls=true`;
    }
    if (opts.hour1 !== undefined) {
      url = url + `&hour1=${opts.hour1}`;
    }
    if (opts.hour2 !== undefined) {
      url = url + `&hour2=${opts.hour2}`;
    }
    if (opts.hour3 !== undefined) {
      url = url + `&hour3=${opts.hour3}`;
    }
    if (opts.timezoneOffsetMS !== undefined) {
      url = url + `&timezoneOffsetMS=${opts.timezoneOffsetMS}`;
    }
    return (await appAxios.get(url)).data;
  }

  public async exportAlertRecords(accountId: number, opts: { siteId?: number; locationId?: number; monitoringPointId?: number; timestamp?: string }) {
    const queryParams = new URLSearchParams();
    let queryString = '';
    queryString = `?${queryParams.toString()}`;
    const url = `/projects/${accountId}/alerts/export${queryString}`
  }
}

export default new EventsService();
