class Validation {
  public isEUI64(value: string): boolean {
    if (!value || !value.length || value.length !== 16) {
      return false;
    }
    return this.isHexString(value);
  }

  public isHexString(hex: string) {
    if (!hex || !hex.length) {
      return false;
    }
    for (let i = 0; i < hex.length; i++) {
      const char = hex.substr(i, 1);
      if (!'0123456789ABCDEF'.includes(char.toUpperCase())) {
        return false;
      }
    }
    return true;
  }
}

export default new Validation();
